export const authToast = {
  sign_up_pending: 'Bitte schließen Sie den Registrierungsprozess für Ihr Konto ab',
  code_resent_success: 'Code erfolgreich erneut gesendet',
  invalid_code: 'Ungültiger Code eingegeben',
  code_expired:
    'Ihr einmaliger Bestätigungscode ist entweder ungültig oder abgelaufen. Bitte klicken Sie auf "Erneut senden", um einen neuen Code anzufordern',
  invalid_invite: 'Ungültiger Einladungscode',
  user_not_exists: 'Benutzer existiert nicht',
  captch_expited: 'Google Captcha abgelaufen',
  user_already_exists: 'Ein Konto mit dieser E-Mail existiert bereits. Bitte einloggen',
  invalid_login:
    'Ungültige Login-Details. Klicken Sie auf Registrieren, um ein neues Konto zu registrieren, oder klicken Sie auf Passwort vergessen, um Ihr Passwort zurückzusetzen',
  toomanycall: 'Sie haben in einem bestimmten Zeitraum zu viele Anfragen gesendet. Versuchen Sie es später noch einmal!'
}

export const register = {
  information: 'Informationen',
  all_rights_reserved: 'CYTRIO, Inc. Alle Rechte vorbehalten',
  verification_code: 'Bestätigungscode',
  moreDetails: 'Mehr über dich',
  about: 'Über',
  help: 'Hilfe',
  privacy_policy: 'Datenschutzerklärung',
  terms_and_conditions: 'Nutzungsbedingungen',
  data_captured:
    'Während der Anmeldung erfasste Daten werden verwendet, um Zugriff auf das Datenschutzzentrum zu gewähren, die Benutzererfahrung zu verbessern und von CYTRIO abonnierte Dienste bereitzustellen',
  pwd_must_meet: 'Passwort muss übereinstimmen',
  following_requirements: 'die folgenden Anforderungen:',
  one_letter: 'Mindestens ein Buchstabe',
  one_capital: 'Mindestens ein Großbuchstabe',
  one_number: 'Mindestens eine Nummer',
  eight: 'Mindestens 8 Zeichen lang sein',
  create_account: 'Konto erstellen',
  company_name: 'Firmenname',
  company_name_required: 'Bitte Firmennamen eingeben',
  first_name: 'Vorname',
  first_name_required: 'Bitte Vornamen eingeben',
  last_name: 'Nachname',
  last_name_required: 'Bitte Nachnamen eingeben',
  email: 'E-Mail',
  email_required: 'Bitte gültige E-Mail-Adresse eingeben',
  phone: 'Telefon',
  phone_required: 'Bitte Telefonnummer eingeben',
  phoneNumberInvalid: 'Ungültige Telefonnummer',
  password: 'Passwort',
  country: 'Land',
  country_required: 'Bitte geben Sie das Land ein',
  state: 'Zustand',
  state_required: 'Bitte geben Sie das Bundesland ein',
  password_required: 'Bitte gültiges Passwort eingeben',
  confirm_password: 'Passwort bestätigen',
  confirm_password_required: 'Bitte bestätigen Sie das Passwort',
  confirm_password_not_match: 'Passwort stimmt nicht überein',
  agree: 'Ich stimme CYTRIO zu',
  terms: 'Allgemeine Geschäftsbedingungen',
  terms_required: 'Bitte akzeptieren Sie die Allgemeinen Geschäftsbedingungen',
  already_registered: 'Schon registriert?',
  sign_in: 'Anmelden',
  trouble_sign_up: 'Wenn Sie Probleme bei der Anmeldung haben, bitte',
  contact_admin: 'Administrator kontaktieren',
  captcha_required: 'Bitte überprüfen Sie das Google Captcha',
}

export const verifyCode = {
  title: 'Datenschutzcenter',
  resend: 'Erneut senden',
  sent_to_email: 'Ein 6-stelliger Bestätigungscode wurde an Ihre E-Mail-ID gesendet',
  enter_code: 'Bitte geben Sie den Code unten ein',
  submit_btn: 'Bestätigen',
  valid_for: 'Ihr Code ist gültig für',
  didnt_receive: 'Keinen Code erhalten?',
  code_required: 'Bitte geben Sie den Code ein',
  valid_code: 'Bitte nur Ziffern eingeben',
  mins: 'Minuten',
}

export const sendCode = {
  title: 'Bestätigungscode senden',
  send_code_to: '6-stelligen Verifizierungscode senden an ',
  send_code: 'Code senden',
  email: 'E-Mail',
  phone: 'Telefon',
  email_text: 'Firma...@name.com',
  phone_text: '1234-567-890',
}

export const login = {
  login: 'Anmelden',
  sign_in_button: 'Anmelden',
  sign_in: 'Datenschutzcenter',
  sign_in_cred: 'Melden Sie sich mit Ihren Zugangsdaten an',
  sign_up: 'Anmelden',
  email: 'E-Mail',
  password: 'Passwort',
  dont_have_account: 'Sie haben kein Konto?',
  create_account: 'Konto erstellen',
  troubles: 'Wenn Sie Probleme beim Anmelden haben, bitte',
  contact_admin: 'Administrator kontaktieren',
  forgot_password: 'Passwort vergessen?',
  remember_password: 'Passwort merken?',
  data_captured:
    'Während der Anmeldung erfasste Daten werden verwendet, um Zugriff auf das Datenschutzzentrum zu gewähren, die Benutzererfahrung zu verbessern und von CYTRIO abonnierte Dienste bereitzustellen',
}

export const moreDetails = {
  tellUsMore: 'Erzählen Sie uns mehr über sich selbst',
  dateOfBirth: 'Geburtsdatum',
  dateOfBirth_required: 'Bitte geben Sie das Geburtsdatum ein',
  zipcode: 'Postleitzahl',
  address: 'Adresse',
  city: 'Stadt',
}

export const forgetPass = {
  forgetPass: 'Passwort vergessen?',
  forgetPassDescription:
    'Geben Sie Ihre E-Mail-Adresse ein und wir senden Ihnen einen Link zum Zurücksetzen Ihres Passworts',
  emaildidNotFound: 'Diese Adresse ist entweder ungültig oder keinem Konto zugeordnet',
  backToLogin: 'Zurück zum Login',
  passwordResetEmail: 'E-Mail zum Zurücksetzen des Passworts gesendet',
  passwordResetEmailContent: 'Eine E-Mail wurde an Ihre registrierte E-Mail-Adresse gesendet.',
  passwordResetSubContent: 'Klicken Sie auf den Link in der E-Mail, um das Passwort zurückzusetzen.',
  linkExpired: 'Dieser Link ist abgelaufen',
  linkExpiredContent: 'Klicken Sie hier, um einen neuen Link zu senden',
  resendLink: 'Link erneut senden',
  resetPass: 'Passwort zurücksetzen',
  resetPassContent: 'Geben Sie Ihr neues Passwort ein',
  passwordChanged: 'Passwort geändert',
  passwordChangedSuccess: 'Ihr Passwort wurde erfolgreich geändert.',
  newPassword: 'Neues Passwort',
  newPasswordSameOld: 'Neues Passwort kann nicht dasselbe wie vorheriges (altes) Passwort sein',
  addressnotfound:'Wenn der Benutzer vorhanden ist, wird eine E-Mail gesendet.'
}
