import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Home from './Modules/Home'
import { ThemeProvider } from 'styled-components'
import { AppStateType } from './Store/reducers'
import { useLocation } from 'react-router-dom'
import LoadingOverlay from 'react-loading-overlay-ts'
import { ToastContainer } from 'react-toastify'
import { actionConfigChange } from './Modules/ThemeConfig/Actions/config.actions'
import NotFound from './Modules/Home/NotFound'
import Loader from './Modules/Common/Components/Loader'
import LogOutAllTabs from 'Utils/LogOutAllTabs'

import './App.css'
import 'react-toastify/dist/ReactToastify.css'
import { loadEnvironmentVariables } from 'Config'

function App() {
  const { themeConfig, tenantNotFound } = useSelector((state: AppStateType) => state.ConfigReducer)
  const { isLoading, loadingText } = useSelector((state: AppStateType) => state.LoaderReducer)

  const location = useLocation()
  const dispatch = useDispatch()
  let tenantUrl = location.pathname.split('/')[1]

  useEffect(() => {
    LogOutAllTabs.logOutAllTabs()
  })

  useEffect(() => {
    if (!tenantUrl) {
      return
    }
    loadEnvironmentVariables().then(() => {
      dispatch(actionConfigChange(tenantUrl))
    })
  }, [tenantUrl, dispatch])

  if (tenantNotFound || !tenantUrl) {
    return <NotFound />
  }

  if (!themeConfig) {
    return <Loader />
  }

  return (
    <ThemeProvider theme={themeConfig}>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <LoadingOverlay classNamePrefix="loading" active={isLoading} spinner text={loadingText}>
        <Home tenantUrl={tenantUrl} />
      </LoadingOverlay>
    </ThemeProvider>
  )
}

export default App
