export const profileSettings = {
  toast: {
    wrong_current_password: 'Mot de passe actuel invalide',
    change_password_success: 'Mot de passe mis à jour avec succès',
  },
  title: 'Paramètres du profil',
  profile_picture: 'Photo de profil',
  profile_details: 'Détails du profil',
  change_your_password: 'Changer votre mot de passe',
  first_name: 'Prénom',
  first_name_required: 'Veuillez entrer le prénom',
  last_name: 'Nom de famille',
  last_name_required: 'Veuillez entrer le nom de famille',
  email: 'Courriel professionnel',
  email_required: 'Veuillez entrer un e-mail',
  phone: 'Téléphone',
  phone_required: 'Veuillez entrer le téléphone',
  current_password: 'Mot de passe actuel',
  current_password_required: 'Veuillez saisir le mot de passe actuel',
  new_password_cant_same: 'Le nouveau mot de passe et le mot de passe actuel ne peuvent pas être identiques',
  new_password: 'Nouveau mot de passe',
  new_password_required: 'Veuillez entrer un nouveau mot de passe valide',
  confirm_password: 'Confirmer le mot de passe',
  confirm_password_required: 'Confirmer le nouveau mot de passe',
  confirm_password_not_match: 'Le mot de passe ne correspond pas',
  change_photo: 'Changer de photo',
  delete: 'Supprimer',
}
