export enum BroadcastChannelNames {
  Logout = 'logout',
}
export enum BroadcastChannelMessages {
  Logout = 'logoutSystem',
}

export enum Pages {
  LoginPage = 'login',
  RegisterPage = 'register',
  VerifyCodePage = 'verifyCode',
  SendCodePage = 'sendCode',
  MoreDetailsPage = 'moreDetails',
  ForgotPassword = 'forgotPassword',
  ResetPassword = 'resetPassword',
}

export enum SessionStorageKeyNames {
  TenantUrl = 'tenant_url',
  AccessToken = 'access_token',
  TempToken = 'temp_token',
  TempTokenExpiry = 'temp_token_expiry',
  UserData = 'user_data',
  Language = 'language',
}

export enum CommonColors {
  HeaderProfileIcon = '#FFFFFF',
  SideBarExpandIcon = '#FFFFFF',
}

export enum ResponseCodes {
  Success = 2000,
  AlreadyExist = 4001,
  Unauthorized = 4001,
  CreatedSuccessfully = 2001,
  InvalidParams = 4000,
  InvalidEmail = 4003,
  NoUserFound = 4004,
  Conflicts = 4009,
  InValidDomain = 4005,
  Expired = 4008,
  ExpiredCode = 4002,
  ConflictsRequest = 4091,
  NotFound = 4004,
  AddressNotFound = 3002
}

export enum ReuqestType {
  Access = 'rt_access',
  Delete = 'rt_delete',
  DoNotSell = 'rt_donotsell'
}
export enum CustomerType {
  Customer = 'customer',
  Employee = 'employee',
}
export enum RequestStatus {
  InProgress = 'InProgress',
  Completed = 'Completed',
  Error = 'Error',
}

export enum LogoutStatus {
  status = 'sessionExpired',
}
