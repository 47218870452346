import { Dispatch } from 'react'
import { Action } from 'redux'
import service from '../Services/forget.password'
import { loaderStart, loaderStop } from '../../Loader/Reducers'
import toastService from '../../Common/Services/Toaster'
import { I18n } from 'react-redux-i18n'
import { ResetPasswordModel } from '../Models/user.model'
import { ApiResponse } from '../../Common/Models'
import { ResponseCodes } from '../../Common/Enums/Common'

export const ACTION_RESET_PASSWORD = 'ACTION_RESET_PASSWORD'
export const ACTION_RESET_PASSWORD_SUCCESS = 'ACTION_RESET_PASSWORD_SUCCESS'
export const ACTION_RESET_PASSWORD_FAILED = 'ACTION_RESET_PASSWORD_FAILED'

export interface ActionResetPassword extends Action {
  type: typeof ACTION_RESET_PASSWORD
}

export interface ActionResetPasswordSuccess extends Action {
  type: typeof ACTION_RESET_PASSWORD_SUCCESS
  payload: { data: ApiResponse<string> }
}

export interface ActionResetPasswordFailed extends Action {
  type: typeof ACTION_RESET_PASSWORD_FAILED
  payload: {
    data: ApiResponse<string>
  }
}

export type ResetPasswordActions = ActionResetPassword | ActionResetPasswordFailed | ActionResetPasswordSuccess

export const dispatchResetPasswordSuccess = (): ActionResetPassword => ({
  type: ACTION_RESET_PASSWORD,
})

const dispatchResetPasswordSuccessSuccess = (data: ApiResponse<string>): ActionResetPasswordSuccess => ({
  type: ACTION_RESET_PASSWORD_SUCCESS,
  payload: {
    data,
  },
})

const dispatchResetPasswordSuccessFailed = (data: ApiResponse<string>): ActionResetPasswordFailed => ({
  type: ACTION_RESET_PASSWORD_FAILED,
  payload: { data },
})
const toaster = (code: number) => {
  switch (code) {
    case ResponseCodes.InvalidParams:
      toastService.error(I18n.t('auth_toast.invalid_code'))
      break
    case ResponseCodes.ExpiredCode:
      toastService.error(I18n.t('auth_toast.code_expired'))
      break
    case ResponseCodes.NotFound:
      toastService.error(I18n.t('auth_toast.user_not_exists'))
      break
    case ResponseCodes.Conflicts:
      toastService.error(I18n.t('forgetPass.newPasswordSameOld'))
      break
    default:
      toastService.error(I18n.t('common.toast.something_went_wrong'))
      break
  }
}

export function actionResetPassword(
  restPass: ResetPasswordModel,
  tenantUrl: string
): (dispatch: Dispatch<ResetPasswordActions>) => Promise<void> {
  return async (dispatch: Dispatch<ResetPasswordActions>) => {
    dispatch(dispatchResetPasswordSuccess())
    loaderStart(dispatch)
    try {
      const result = await service.resetPassword(restPass, tenantUrl)
      if (result && result.success) {
        dispatch(dispatchResetPasswordSuccessSuccess(result))
      } else {
        toaster(result?.statusCode)
        dispatch(dispatchResetPasswordSuccessFailed(result))
      }
      loaderStop(dispatch)
    } catch (error) {
      let result: ApiResponse<string> = {
        message: error,
        success: false,
      }
      dispatch(dispatchResetPasswordSuccessFailed(result))
      loaderStop(dispatch)
    }
  }
}
