import { http } from '../../../../Utils/AxiosWrapper/index'
import { ApiResponse, DropdownModel } from '../../Models'
import { common } from '../../../Common/Constants/ApiUrls'

interface CitiesService {
  getCitiesDropdown(state: string, country: string, tenantUrl: string): Promise<ApiResponse<Array<DropdownModel>>>
}

class CitiesServiceDefault implements CitiesService {
  async getCitiesDropdown(state: string, country: string, tenantUrl: string): Promise<ApiResponse<Array<DropdownModel>>> {
    let response: ApiResponse<Array<DropdownModel>> = {
      data: [],
      message: '',
      success: false,
    }
    try {
      const apiData = await http.get(`${common.cities(tenantUrl)}?state_code=${state}&iso=${country}`)
      if (apiData && apiData.data && apiData.data.city_list && apiData.data.city_list.length > 0) {
        apiData.data.city_list.forEach((loc: string) => {
          let city: DropdownModel = {
            value: loc,
            label: loc,
            lableSecond: loc,
          }
          response.data?.push(city)
        })
        response.success = true
        response.message = 'Success'
      } else {
        response = {
          success: false,
          message: '',
          data: [],
        }
      }
    } catch (error) {
      response = {
        success: false,
        message: '',
        data: [],
      }
    }
    return response
  }
}

export default new CitiesServiceDefault()
