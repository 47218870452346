import { http } from '../../../Utils/AxiosWrapper'
import { AddRequest } from '../Models'
import { dsar } from '../../Common/Constants/ApiUrls'
import { ApiResponse, PageParams } from '../../Common/Models'
import { ResponseCodes } from '../../Common/Enums/Common'
import { buildParams } from '../../../Utils/queryFormat'

interface DSARRequest {
  addRequest(addRequest: AddRequest, tenantUrl: string): Promise<ApiResponse<string>>
  getRequests(request: PageParams, tenantUrl: string): Promise<ApiResponse<any>>
}
class DSARRequestService implements DSARRequest {
  public async addRequest(addRequest: AddRequest, tenantUrl: string): Promise<ApiResponse<string>> {
    let response: ApiResponse<string>
    try {
      const { data } = await http.request({
        method: 'post',
        url: dsar.request(tenantUrl),
        data: addRequest,
      })
      if (data) {
        response = {
          success: true,
          message: '',
          statusCode: data.code,
        }
      } else {
        response = {
          success: false,
          message: 'Empty response.',
          statusCode: ResponseCodes.NoUserFound,
        }
      }
    } catch (error) {
      response = {
        success: false,
        message: error.response.data.message,
        statusCode: error.response.data.code,
      }
    }
    return response
  }
  async getRequests(request: PageParams, tenantUrl: string): Promise<ApiResponse<any>> {
    let result: ApiResponse<any>
    try {
      const { data } = await http.request({
        method: 'get',
        url: dsar.request(tenantUrl),
        params: buildParams(request),
      })
      result = DSARRequestService.buildResponse(data)
    } catch (error) {
      result = {
        success: false,
        message: error.response.data.message,
        statusCode: error.response.data.code,
      }
    }
    return result
  }

  static buildResponse(data: any): ApiResponse<any> {
    if (!data) {
      return {
        success: false,
        statusCode: ResponseCodes.InvalidParams,
        message: 'Empty data in response',
      }
    }

    if (data.code === ResponseCodes.NotFound) {
      return {
        success: false,
        statusCode: data.code,
        message: data.message,
      }
    }
    return {
      success: true,
      statusCode: data.code,
      message: data.message || '',
      data: data.data,
      totalRecords: data.total_records,
      activePage: parseInt(data.page),
      doNotSellRequest: data?.do_not_sell_request,
    }
  }
}

export default new DSARRequestService()
