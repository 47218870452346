import { Dispatch } from 'react'
import { Action } from 'redux'
import { ThemeConfig } from '../Models/theme-config.model'
import service from '../Services/config.service'

export const ACTION_CONFIG_CHANGE = 'ACTION_CONFIG_CHANGE'
export const ACTION_CONFIG_CHANGE_SUCCESS = 'ACTION_CONFIG_CHANGE_SUCCESS'
export const ACTION_CONFIG_CHANGE_FAILURE = 'ACTION_CONFIG_CHANGE_FAILURE'

export interface ActionConfigChange extends Action {
  type: typeof ACTION_CONFIG_CHANGE
}

export interface ActionConfigChangeSuccess extends Action {
  type: typeof ACTION_CONFIG_CHANGE_SUCCESS
  payload: { config?: ThemeConfig; tenantUrl: string; tenantName: string }
}

export interface ActionConfigChangeFailure extends Action {
  type: typeof ACTION_CONFIG_CHANGE_FAILURE
  payload: { errorMessage: string }
}

export type UserConfigActions = ActionConfigChange | ActionConfigChangeFailure | ActionConfigChangeSuccess

export const dispatchConfigChangeSuccess = (
  config?: ThemeConfig,
  tenantUrl: string = '',
  tenantName: string = ''
): ActionConfigChangeSuccess => ({
  type: ACTION_CONFIG_CHANGE_SUCCESS,
  payload: { config, tenantUrl, tenantName },
})

const dispatchConfigChange = (): ActionConfigChange => ({
  type: ACTION_CONFIG_CHANGE,
})

const dispatchConfigChangeFailed = (error: Error): ActionConfigChangeFailure => ({
  type: ACTION_CONFIG_CHANGE_FAILURE,
  payload: { errorMessage: error.message },
})

export function actionConfigChange(tenantUrl: string): (dispatch: Dispatch<UserConfigActions>) => Promise<void> {
  return async (dispatch: Dispatch<UserConfigActions>) => {
    dispatch(dispatchConfigChange())
    try {
      /* Check if tenant is active or not */
      const result = await service.checkTenant(tenantUrl)
      if (!result.success || !result.tenantId) {
        dispatch(dispatchConfigChangeFailed(new Error(result.message)))
        return
      }
      /* Get tenant config */
      const themResult = await service.getThemeConfig(tenantUrl, result.tenantId, result.domainId)
      if (!themResult.success) {
        dispatch(dispatchConfigChangeFailed(new Error(themResult.message)))
        return
      }
      dispatch(dispatchConfigChangeSuccess(themResult.themeConfig, themResult.tenantUrl, result.tenantName))
    } catch (error: any) {
      dispatch(dispatchConfigChangeFailed(error))
    }
  }
}
