import { ResetPasswordModel } from '../Models/user.model'
import { ApiResponse } from '../../Common/Models/index'
import { http } from '../../../Utils/AxiosWrapper'
import { auth } from '../../Common/Constants/ApiUrls'
interface ForgotPassword {
  sendForgotPasswordLink(email: string, tenantUrl: string): Promise<ApiResponse<string>>
  verifyPasswordLink(UUID: string, tenantUrl: string): Promise<ApiResponse<string>>
  resetPassword(resetPass: ResetPasswordModel, tenantUrl: string): Promise<ApiResponse<string>>
}

class ForgotPasswordDefault implements ForgotPassword {
  async sendForgotPasswordLink(email: string, tenantUrl: string): Promise<ApiResponse<string>> {
    let result: ApiResponse<string>
    try {
      const { data } = await http.request({
        method: 'post',
        url: auth.forgotPassword(tenantUrl),
        data: { email },
      })
      result = {
        success: true,
        statusCode: data.code,
        message: data.msg,
        data: data.msg,
      }
    } catch (error) {
      result = {
        success: false,
        message: error.response.data.message,
        statusCode: error.response.data.code,
        data: error.response.data.message,
      }
    }
    return result
  }
  async verifyPasswordLink(UUID: string, tenantUrl: string): Promise<ApiResponse<string>> {
    let result: ApiResponse<string>
    try {
      const { data } = await http.request({
        method: 'get',
        url: auth.verifyForgotPasswordLink(tenantUrl),
        params: { reset_link_uuid: UUID },
      })
      result = {
        success: true,
        statusCode: data.code,
        message: data.msg,
        data: data.msg,
      }
    } catch (error) {
      result = {
        success: false,
        message: error.response.data.message,
        statusCode: error.response.data.code,
        data: error.response.data.message,
      }
    }
    return result
  }
  async resetPassword(resetPass: ResetPasswordModel, tenantUrl: string): Promise<ApiResponse<string>> {
    let result: ApiResponse<string>
    try {
      const { data } = await http.request({
        method: 'post',
        url: auth.resetPassword(tenantUrl),
        data: resetPass,
      })
      result = {
        success: true,
        statusCode: data.code,
        message: data.msg,
        data: data.msg,
      }
    } catch (error) {
      result = {
        success: false,
        message: error.response.data.message,
        statusCode: error.response.data.code,
        data: error.response.data.message,
      }
    }
    return result
  }
}

export default new ForgotPasswordDefault()
