import { I18n } from 'react-redux-i18n'
import './NotFound.css'

const NotFound = () => {
  return (
    <div id="notfound">
      <div className="notfound">
        <div className="notfound-404">
          <h1>
            4<span>0</span>4
          </h1>
        </div>
        <h2>{I18n.t('common.not_found')}</h2>
      </div>
    </div>
  )
}

export default NotFound
