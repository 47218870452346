export const profileSettings = {
  toast: {
    wrong_current_password: 'Contraseña actual no válida',
    change_password_success: 'Contraseña actualizada correctamente',
  },
  title: 'Configuración de perfil',
  profile_picture: 'Imagen de perfil',
  profile_details: 'Detalles del perfil',
  change_your_password: 'Cambiar su contraseña',
  first_name: 'Nombre',
  first_name_required: 'Ingrese el primer nombre',
  last_name: 'Apellido',
  last_name_required: 'Ingrese el apellido',
  email: 'Correo electrónico comercial',
  email_required: 'Ingrese el correo electrónico',
  phone: 'Teléfono',
  phone_required: 'Ingrese el teléfono',
  current_password: 'Contraseña actual',
  current_password_required: 'Ingrese la contraseña actual',
  new_password_cant_same: 'La nueva contraseña y la contraseña actual no pueden ser iguales',
  new_password: 'Nueva contraseña',
  new_password_required: 'Ingrese una nueva contraseña válida',
  confirm_password: 'Confirmar contraseña',
  confirm_password_required: 'Confirmar nueva contraseña',
  confirm_password_not_match: 'La contraseña no coincide',
  change_photo: 'Cambiar foto',
  delete: 'Borrar',
}
