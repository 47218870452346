import { register, verifyCode, sendCode, login, authToast, moreDetails, forgetPass } from './Auth/en'
import { request } from './Requests/en'
import { profileSettings } from './ProfileSettings/en'

export const en = {
  header: {
    logout: 'Logout',
    settings: 'Profile Settings',
    help: 'Help',
  },
  sidebar: {
    dsar: 'DSAR',
    tenant_health: 'Tenant Health',
    application_health: 'Application Health',
    dsar_workspace: 'DSAR Workspace',
    tenants: 'Tenants',
    users: 'Users',
    scanners: 'Scanners',
    data_sources: 'Data Sources',
    classifications: 'Classifications',
    privacy_portal: 'Privacy Portal',
    system_settings: 'System Settings',
    terms_of_service: 'Terms of Service',
  },
  common: {
    send: 'Send',
    on: 'On',
    off: 'Off',
    save: 'Save',
    submit: 'Submit',
    cancel: 'Cancel',
    done: 'Done',
    add: 'Add',
    update: 'Update',
    location: 'Location',
    locationRequired: 'Location is required',
    invalidEmail: 'Invalid email address format',
    invalidUrl: 'Invalid URL address',
    toast: {
      something_went_wrong: 'Something went wrong',
      no_results_found: 'No results found.',
      jwtTokenExpired: 'Your session has expired',
    },
    next: 'Next',
    saveAndNext: 'Save & Next',
    back: 'Back',
    invite: 'Invite',
    not_found: 'the page you requested, could not be found',
    error_boundry_text: 'There was an error in loading this section.',
    error_boundry_reload: 'Reload this page',
    ok: 'OK',
    select: 'Select..',
    showingToOfResults: 'Showing %{from} to %{to} of %{total} results',
  },
  profile_settings: profileSettings,
  auth_toast: authToast,
  login: login,
  send_code: sendCode,
  verify_code: verifyCode,
  register: register,
  moreDetails: moreDetails,
  request: request,
  forgetPass: forgetPass,
}

export type LocaleMessagesType = typeof en
