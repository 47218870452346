import cx from 'classnames'
import React from 'react'
import styles from '../Auth.module.css'
import ButtonWithIcon from 'Modules/Common/Components/Button'
import { useHistory } from 'react-router'
import { I18n } from 'react-redux-i18n'
import ModalPopup from '../../Common/Components/Model'
import { bodyChildContent } from '../Common'

interface PasswordChangePopupProps {
  isShowPopup: boolean
  setIsShowPopup: (isShow: boolean) => void
}

function PasswordChangePopup(props: PasswordChangePopupProps) {
  const { isShowPopup, setIsShowPopup } = props
  const history = useHistory()
  let bodyChild: JSX.Element = (
    <div className="row mr-2 ml-2">
      {bodyChildContent(true)}
      <div className={cx('col-md-12 mt-4 d-flex justify-content-center')}>
        <ButtonWithIcon
          type="button"
          text={I18n.t('common.ok')}
          style={{ width: '50%' }}
          x-automation-id="ok-btn-changepass"
          id="next-btn"
          className={styles.customButton}
          onClick={() => {
            history.push('/login')
            setIsShowPopup(false)
          }}
        />
      </div>
    </div>
  )
  return (
    <>
      <ModalPopup
        show={isShowPopup}
        dialogClassName="sentModal"
        x-automation-id="add-datasource-popup"
        bodyChild={bodyChild}
        onHide={() => setIsShowPopup(false)}
      />
    </>
  )
}

export default React.memo(PasswordChangePopup)
