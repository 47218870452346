export const request = {
  open: 'Open',
  close: 'Closed',
  completed: 'Completed',
  error: 'Error',
  inProgress: 'In Progress',
  dsar: 'Data Access Requests',
  iam: 'I am a',
  customer: 'Customer',
  employee: 'Employee',
  userTypeRequired: 'User type is required',
  chooseRequestType: 'Choose type of request',
  requestTypeRequired: 'Request type is required',
  rightToAccess: 'Access',
  rightToAccessDescription: 'We will send you a full report containing data we have about you',
  rightToDelete: 'Delete',
  rightToDeleteDescription: 'We will provide you a full report of your personal data that we deleted from our systems',
  rightToDoNotSell: 'Do Not Sell',
  rightToDoNotSellDescription:
    'We will stop sharing/selling your personal information to any of our advertising partners.',
  submitRequest: 'Submit Request',
  submitRequestDescription: 'Provide the following information to Submit the Request',
  duplicateRequest: 'A similar request is already in process.  Please select a different request type',
  requestSubmit: 'Request submitted',
  noDataList: 'No data subject access request exist',
  noDataListSubHeadig: 'Submit Request to proceed',
  requestType: 'Request Type',
  requestId: 'Request Id',
  submitDate: 'Submit Date',
  status: 'status',
  completedDate: 'Completed Date',
  downeloadReport: 'Download Report',
  downloadZip: 'Download Zip',
  searchBy: 'Search by Request Id',
  submitReqDescription:
    'We are committed to respecting your privacy rights and the personal data you share with us.  As part of that commitment, Privacy Center puts you in charge of your data and helps us efficiently fulfill your data privacy requests.  You have the right to obtain a copy of your data and the right to delete your data.',
}
