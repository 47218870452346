import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { User, HelpCircle } from 'react-feather'
import styles from '../Layout.module.css'
import { I18n } from 'react-redux-i18n'
import { CommonColors } from '../../Common/Enums/Common'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { actionLogout } from '../../Auth/Actions/logout.actions'
import { AppStateType } from '../../../Store/reducers'
import LanguageChooser from 'Modules/Common/Components/LanguageChooser'

const Header = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { tenantUrl, themeConfig } = useSelector((state: AppStateType) => state.ConfigReducer)

  let color = themeConfig?.menuStyleColor
  let textColor = themeConfig?.textColor
  let profileIconColor = themeConfig?.profileIconColor

  const onLogoutClick = () => {
    dispatch(actionLogout(tenantUrl))
  }
  const onSettingsClick = () => {
    history.push('/profile-settings')
  }
  const onLogoClick = () => {
    history.push('/requests')
  }

  const CustomToggle = React.forwardRef(({ onClick }: any, ref: any) => (
    <a
      href=""
      ref={ref}
      onClick={e => {
        e.preventDefault()
        onClick(e)
      }}
      id="headerProfielIcon"
    >
      <div className={styles.profileIcon} style={{ background: profileIconColor }}>
        <User color={CommonColors.HeaderProfileIcon} />
      </div>
    </a>
  ))

  return (
    <div className={styles.header} style={{ background: color }}>
      <div className="row w-100 d-flex justify-content-between align-items-center">
        <div className="d-flex mt-1 align-items-center">
          <img src={themeConfig?.logo} className={styles.logo} onClick={onLogoClick} />
          <span className={styles.headerTitle} style={{ color: textColor }}>
            {I18n.t('verify_code.title')}
          </span>
        </div>
        <div className="d-flex align-items-center">
          <div className="d-flex pr-1" style={{ minWidth: '125px' }}>
            <LanguageChooser singleValueColor={textColor ? textColor : '#FFFFFF'} />
          </div>
          <Dropdown className="mt-1">
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" />
            <Dropdown.Menu>
              <Dropdown.Item id="profile-settings-option" eventKey="1" onClick={onSettingsClick}>
                {I18n.t('header.settings')}
              </Dropdown.Item>
              <Dropdown.Item id="logout-option" eventKey="2" onClick={onLogoutClick}>
                {I18n.t('header.logout')}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  )
}

export default Header
