import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'
import { ArrowRight, Lock } from 'react-feather'
import ButtonWithIcon, { CancelButtonWithIcon } from '../../Common/Components/Button'
import styles from '../Auth.module.css'
import cx from 'classnames'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'querystring'
import convertToString from '../../../Utils/queryFormat'
import { AppStateType } from '../../../Store/reducers'
import ResetLinkExpiredPopup from './ResetLinkExpiredPopup'
import PasswordChangePopup from './PasswordChangePopup'
import { actionVerifyResetPasswordCode, dispatchVerifyResetPasswordCode } from '../Actions/verify-password-link.actions'
import { actionResetPassword, dispatchResetPasswordSuccess } from '../Actions/reset-password.actions'
import { ResponseCodes } from '../../Common/Enums/Common'
import PasswordInput, { validatePasswordInput } from 'Modules/Common/Components/PasswordInput'
import { LanguageChooserColMD } from '../CommonCode'

const ResetPassword = () => {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm()

  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()

  const { isAuthenticated } = useSelector((state: AppStateType) => state.AuthReducer)
  const resetPassword = useSelector((state: AppStateType) => state.AuthReducer.resetPassword)
  const resetVerifyPassword = useSelector((state: AppStateType) => state.AuthReducer.verifyResetPasswordLink)
  const { tenantUrl } = useSelector((state: AppStateType) => state.ConfigReducer)

  if (isAuthenticated) {
    history.push('/')
  }
  const [isShowPopup, setIsShowPopup] = useState(false)
  const [isShowChangPopup, setIsShowChangPopup] = useState(false)
  const [hashCode, setHashCode] = useState<string>('')

  useEffect(() => {
    if (!hashCode) {
      return
    }
    dispatch(actionVerifyResetPasswordCode(hashCode, tenantUrl))
    dispatch(dispatchVerifyResetPasswordCode())
  }, [hashCode])

  useEffect(() => {
    if (resetPassword?.success) {
      setIsShowChangPopup(true)
      dispatch(dispatchResetPasswordSuccess())
    }
  }, [resetPassword])

  useEffect(() => {
    if (!resetVerifyPassword?.success && resetVerifyPassword?.statusCode === ResponseCodes.ExpiredCode) {
      setIsShowPopup(true)
    }
  }, [resetVerifyPassword])

  useEffect(() => {
    const { reset_password_id: resetPasswordId } = queryString.parse(location.search.substr(1))

    if (!resetPasswordId) {
      onCancelClick()
      return
    }
    setHashCode(convertToString(resetPasswordId))
  }, [location.search])

  const onSubmit = (data: any) => {
    dispatch(
      actionResetPassword(
        {
          password: getValues('password'),
          uuid: hashCode,
        },
        tenantUrl
      )
    )
  }

  const onCancelClick = () => {
    history.push('/login')
  }

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
      <input type="hidden" {...register('inviteCode')} />
      <div className="row p-0 m-5">
        <LanguageChooserColMD label={I18n.t('forgetPass.resetPass')} subLabel={I18n.t('forgetPass.resetPassContent')} />
        <div className="row p-0 m-0 w-100">
          <div className="col-md-12">
            <Form.Group className={cx(styles.minHeightBox, 'm-0')}>
              <Form.Label className={cx(styles.inputLabel, styles.required)}>
                {I18n.t('forgetPass.newPassword')}
              </Form.Label>
              <PasswordInput
                className={cx(styles.formInput, styles.loginFormInput)}
                x-automation-id="password"
                id="password"
                {...register('password', {
                  required: 'register.password_required',
                  validate: {
                    valid: () =>
                      Object.values(validatePasswordInput(getValues('password'))).every(e => e) ||
                      'register.password_required',
                  },
                })}
                placeholder={I18n.t('register.password')}
                isInvalid={errors.password}
                getValues={() => getValues('password')}
              />
              <span className={styles.loginFormInputIcon}>
                <Lock color="#000000" size={20} />
              </span>
              <Form.Control.Feedback type="invalid" className="m-0">
                {I18n.t(errors?.password?.message || '')}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
          <div className="col-md-12">
            <Form.Group className={cx(styles.minHeightBox, 'm-0')}>
              <Form.Label className={cx(styles.inputLabel, styles.required)}>
                {I18n.t('register.confirm_password')}
              </Form.Label>
              <Form.Control
                className={cx(styles.formInput, styles.loginFormInput)}
                type="password"
                x-automation-id="confirm-password"
                id="confirm-password"
                {...register('confirmPassword', {
                  required: 'register.confirm_password_required',
                  validate: {
                    positive: v => {
                      const p = getValues('password')
                      return p == v || 'register.confirm_password_not_match'
                    },
                  },
                })}
                placeholder={I18n.t('register.confirm_password')}
                isInvalid={errors.confirmPassword}
              />
              <span className={styles.loginFormInputIcon}>
                <Lock color="#000000" size={20} />
              </span>
              <Form.Control.Feedback type="invalid" className="m-0">
                {I18n.t(errors?.confirmPassword?.message || '')}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
        </div>

        <div className="row p-0 m-0 mt-3 w-100 d-flex justify-content-center">
          <div className="col-md-6 d-flex justify-content-end pr-1">
            <CancelButtonWithIcon
              onClick={onCancelClick}
              type="button"
              text={I18n.t('common.cancel')}
              style={{ width: '100%' }}
              x-automation-id="cancel-btn"
              id="cancel-btn"
              className={styles.customButton}
            />
          </div>
          <div className="col-md-6 pl-1">
            <ButtonWithIcon
              type="submit"
              text={I18n.t('common.done')}
              style={{ width: '100%' }}
              x-automation-id="next-btn"
              id="next-btn"
              icon={<ArrowRight color="#FFFFFF" />}
              iconToRight={true}
              className={styles.customButton}
            />
          </div>
        </div>
      </div>
      <ResetLinkExpiredPopup isShowPopup={isShowPopup} setIsShowPopup={setIsShowPopup} />
      <PasswordChangePopup isShowPopup={isShowChangPopup} setIsShowPopup={setIsShowChangPopup} />
    </Form>
  )
}

export default ResetPassword
