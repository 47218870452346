import styled from 'styled-components'
import { Icon } from 'react-feather'
import { ReactElement } from 'react'

interface ButtonProps {
  text: string
  icon?: ReactElement<Icon>
  iconToRight?: boolean
}

const StyledButton = styled.button<{ icontoright: string }>`
  display: flex;
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 10px;
  background-color: #00b08b;
  border: none;
  color: #ffffff;
  border-radius: 5px;
  flex-direction: ${props => (props.icontoright === 'true' ? 'row' : 'row-reverse')};
  &:disabled {
    cursor: not-allowed;
    opacity: 0.8;
    color: rgba(255, 255, 255, 0.5);
  }
  &:hover {
    background: #048267;
  }
  &:disabled:hover {
    background: #00b08b;
  }
`

const StyledCancelButton = styled.button<{ icontoright: string }>`
  display: flex;
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  color: #1d252d;
  background: rgba(0, 0, 0, 0.05);
  border-color: rgba(61, 100, 239, 0.1);
  flex-direction: ${props => (props.icontoright === 'true' ? 'row' : 'row-reverse')};
  &:disabled {
    cursor: not-allowed;
    background: rgba(0, 0, 0, 0.05);
    color: rgba(0, 0, 0, 0.2);
  }
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
`
const StyledSuccessButton = styled.button<{ icontoright: string }>`
  display: flex;
  font-size: 18px;
  font-weight: 500;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border: none;
  border-radius: 4px;
  color: #00b08b;
  background-color: rgba(0, 176, 139, 0.1);
  border-color: rgba(0, 176, 139, 0.1);
  flex-direction: ${props => (props.icontoright === 'true' ? 'row' : 'row-reverse')};
  &:disabled {
    cursor: not-allowed;
  }
  width: 115px;
  height: 45px;
`

const Span = styled.span`
  padding: 0px 3px;
`

const TextSpan = styled.span`
  padding: 0px 3px;
`

const ButtonWithIcon = ({
  text,
  icon,
  iconToRight,
  ...rest
}: ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <StyledButton icontoright={iconToRight?.toString() || ''} {...rest}>
      {text && <TextSpan>{text}</TextSpan>}
      {icon && <Span>{icon}</Span>}
    </StyledButton>
  )
}

export default ButtonWithIcon

export const CancelButtonWithIcon = ({
  text,
  icon,
  iconToRight,
  ...rest
}: ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <StyledCancelButton icontoright={iconToRight?.toString()} {...rest}>
      {text && <TextSpan>{text}</TextSpan>}
      {icon && <Span>{icon}</Span>}
    </StyledCancelButton>
  )
}

export const SuccessButtonWithIcon = ({
  text,
  icon,
  iconToRight,
  ...rest
}: ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <StyledSuccessButton icontoright={iconToRight?.toString()} {...rest}>
      <TextSpan>{text}</TextSpan>
      {icon ? <Span>{icon}</Span> : <></>}
    </StyledSuccessButton>
  )
}
