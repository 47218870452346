import { I18nActions, ACTION_LOAD_TRANSLATIONS, ACTION_SET_LOCALE } from '../Actions/i18n'

export interface InitialState {
  translations?: any
  locale?: string
}

const initialState: InitialState = {}

const i18Reducer = (
  state: InitialState = initialState, //NOSONAR
  action: I18nActions
) => {
  switch (action.type) {
    case ACTION_LOAD_TRANSLATIONS:
      return {
        ...state,
        translations: action.payload.translations,
      }
    case ACTION_SET_LOCALE:
      return {
        ...state,
        locale: action.payload.locale,
      }
    default:
      return state
  }
}

export default i18Reducer
