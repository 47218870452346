import React, { useState } from 'react'
import styles from '../Auth.module.css'
import cx from 'classnames'
import RegisterForm from './RegisterForm'
import VerifyCode from './VerifyCode'
import { SessionStorageKeyNames, Pages } from '../../Common/Enums/Common'
import { Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { AppStateType } from '../../../Store/reducers'
import { Lock, User } from 'react-feather'
import { I18n } from 'react-redux-i18n'
import { getCurrentYear } from '../../../Utils/dateAndTimeUtils'
import { SessionStorage } from '../../../Utils/SessionStorageWrapper'

const leftDiv = (
  page: Pages,
  showMoreDetails: boolean,
  logo?: string,
  textColor: string = '',
  pcvPolicy: string = '',
  tmsOfUse: string = ''
) => (
  <div className="row d-flex h-100 p-2 m-0">
    <div className="col-md-12">
      <div className="mt-4">
        <img className={styles.cytrioLogo} src={logo} />
      </div>
      <div className={cx(styles.loginText, 'mt-5')}>
        <p
          className={
            page === Pages.RegisterPage && !showMoreDetails ? styles.activeLoginText : styles.inActiveLoginText
          }
          style={{ color: textColor }}
        >
          <span>
            <User color={textColor} />
          </span>
          {I18n.t('register.information')}
        </p>
        <p
          className={page === Pages.VerifyCodePage ? styles.activeLoginText : styles.inActiveLoginText}
          style={{ color: textColor }}
        >
          <span>
            <Lock color={textColor} />
          </span>
          {I18n.t('register.verification_code')}
        </p>
      </div>
    </div>
    <div className="row d-flex align-items-end p-0 m-0 mb-3">
      <div className="col-md-12 d-flex align-items-end">
        <div>
          <p className={cx(styles.desc, 'pb-3')} style={{ color: textColor }}>
            {I18n.t('register.data_captured')}
          </p>
          <div className={cx(styles.footerDiv, 'col-md-12 p-0 d-flex pb-1')}>
            <a
              href={pcvPolicy || 'https://cytrio.com/legal/privacypolicy/'}
              target="_blank"
              className="pr-2"
              style={{ color: textColor }}
            >
              {I18n.t('register.privacy_policy')}
            </a>
            <div className={styles.verticle} style={{ color: textColor }}></div>
            <a
              href={tmsOfUse || 'https://www.cytrio.com/legal/TermsofService/'}
              target="_blank"
              style={{ color: textColor }}
            >
              {I18n.t('sidebar.terms_of_service')}
            </a>
          </div>
          <div className={cx(styles.footerDiv, 'col-md-12 p-0')}>
            <p className="m-0" style={{ color: textColor }}>
              © {getCurrentYear()} {I18n.t('register.all_rights_reserved')}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
)

interface RegisterProps {
  page: Pages
  tenantUrl: string
}

const Register = ({ page, tenantUrl }: RegisterProps) => {
  const { isAuthenticated, isVerified } = useSelector((state: AppStateType) => state.AuthReducer)
  const [showMoreDetails, setShowMoreDetails] = useState(false)
  const themeConfig = useSelector((state: AppStateType) => state.ConfigReducer.themeConfig)

  if (isAuthenticated && isVerified) {
    return <Redirect to="/" />
  }

  const userDataSessionStorage = SessionStorage.get(SessionStorageKeyNames.UserData)
  const userDataParsed = userDataSessionStorage && JSON.parse(userDataSessionStorage)

  if (userDataParsed) {
    return <Redirect to="/" />
  }

  let form
  if (page === Pages.VerifyCodePage) {
    form = <VerifyCode page={Pages.RegisterPage} />
  }

  if (page === Pages.RegisterPage) {
    form = <RegisterForm setShowMoreDetails={setShowMoreDetails} showMoreDetails={showMoreDetails} />
  }
  let menu_style_color = themeConfig?.menuStyleColor
  return (
    <div className={styles.outerDiv}>
      <div className={styles.mainDiv}>
        <div className={styles.innerDiv} style={{ background: menu_style_color }}>
          <div className={styles.leftDiv}>
            {leftDiv(
              page,
              showMoreDetails,
              themeConfig?.logo,
              themeConfig?.textColor,
              themeConfig?.pcvPolicy,
              themeConfig?.tmsUse
            )}
          </div>
          <div className={styles.rightDiv}>{form}</div>
        </div>
      </div>
    </div>
  )
}

export default Register
