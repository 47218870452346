export const authToast = {
  sign_up_pending: 'Please complete your account sign-up process',
  code_resent_success: 'Code resent successfully',
  invalid_code: 'Invalid code entered',
  code_expired:
    "Your one-time verification code is either invalid or has expired. Please click 'Resend' to request for a new code",
  invalid_invite: 'Invalid invitation code',
  user_not_exists: 'User does not exists',
  captch_expited: 'Google captcha expired',
  user_already_exists: 'An account with this email already exists.  Please sign in',
  invalid_login:
    'Invalid login credentials. Click Sign up to register a new account or click Forgot Password to reset your password',
  toomanycall: 'You have sent too many requests in a given amount of time. Try again later!'
}

export const register = {
  information: 'Information',
  all_rights_reserved: 'CYTRIO, Inc. All Rights Reserved',
  verification_code: 'Verification Code',
  moreDetails: 'More about yourself',
  about: 'About',
  help: 'Help',
  privacy_policy: 'Privacy Policy',
  terms_and_conditions: 'Terms of Use',
  data_captured:
    'Data captured during sign up will be used for providing access to Privacy Center, improve user experience and deliver services subscribed from CYTRIO',
  pwd_must_meet: 'Password must meet',
  following_requirements: 'the following requirements:',
  one_letter: 'At least one letter',
  one_capital: 'At least one capital letter',
  one_number: 'At least one number',
  eight: 'Be at least 8 characters',
  create_account: 'Create Account',
  company_name: 'Company Name',
  company_name_required: 'Please enter company name',
  first_name: 'First Name',
  first_name_required: 'Please enter first name',
  last_name: 'Last Name',
  last_name_required: 'Please enter last name',
  email: 'Email',
  email_required: 'Please enter valid email',
  phone: 'Phone',
  phone_required: 'Please enter phone',
  phoneNumberInvalid: 'Invalid Phone Number',
  password: 'Password',
  country: 'Country',
  country_required: 'Please enter the country',
  state: 'State',
  state_required: 'Please enter the state',
  password_required: 'Please enter valid password',
  confirm_password: 'Confirm Password',
  confirm_password_required: 'Please enter confirm password',
  confirm_password_not_match: 'Password does not match',
  agree: "I agree to CYTRIO's",
  terms: 'Terms and Conditions',
  terms_required: 'Please accept terms and conditions',
  already_registered: 'Already registered?',
  sign_in: 'Sign In',
  trouble_sign_up: 'If you have trouble signing up, please',
  contact_admin: 'Contact Administrator',
  captcha_required: 'Please verify the Google Captcha',
}

export const verifyCode = {
  title: 'Privacy Center',
  resend: 'Resend',
  sent_to_email: 'A 6-digit verification code has been sent to your email id',
  enter_code: 'Please enter the code below',
  submit_btn: 'Verify',
  valid_for: 'Your code will be valid for',
  didnt_receive: "Didn't receive a code?",
  code_required: 'Please enter the code',
  valid_code: 'Please enter only digits',
  mins: 'Mins',
}

export const sendCode = {
  title: 'Send Verification Code',
  send_code_to: 'Send 6-digit Verification code to ',
  send_code: 'Send Code',
  email: 'Email',
  phone: 'Phone',
  email_text: 'Company...@name.com ',
  phone_text: '1234-567-890 ',
}

export const login = {
  login: 'Login',
  sign_in_button: 'Sign In',
  sign_in: 'Privacy Center',
  sign_in_cred: 'Sign in with your credentials',
  sign_up: 'Sign up',
  email: 'Email',
  password: 'Password',
  dont_have_account: 'Don’t have an account?',
  create_account: 'Create account',
  troubles: 'If you have trouble signing in, please',
  contact_admin: 'Contact Administrator',
  forgot_password: 'Forgot password?',
  remember_password: 'Remember password?',
  data_captured:
    'Data captured during sign in will be used for providing access to Privacy Center, improve user experience and deliver services subscribed from CYTRIO',
}

export const moreDetails = {
  tellUsMore: 'Tell us more about your self',
  dateOfBirth: 'Date of Birth',
  dateOfBirth_required: 'Please enter the Date of Birth',
  zipcode: 'Zip Code',
  address: 'Address',
  city: 'City',
}

export const forgetPass = {
  forgetPass: 'Forgot your password?',
  forgetPassDescription: 'Enter your e-mail address and we’ll send you a link to reset your password',
  emaildidNotFound: 'That address is either invalid or not associated with any account',
  backToLogin: 'Back to login',
  passwordResetEmail: 'Password reset email sent',
  passwordResetEmailContent: 'An email has been sent to your registered email address.',
  passwordResetSubContent: 'Click on the link provided in the email to reset password.',
  linkExpired: 'This link has expired',
  linkExpiredContent: 'Click here to send a new link',
  resendLink: 'Resend link',
  resetPass: 'Reset password',
  resetPassContent: 'Enter your new password',
  passwordChanged: 'Password Changed',
  passwordChangedSuccess: 'Your password has been changed successfully.',
  newPassword: 'New Password',
  newPasswordSameOld: 'New password cannot be same as previous(old) password',
  addressnotfound:'If user exists, email will be sent.'
}
