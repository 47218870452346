import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'
import { ArrowRight } from 'react-feather'
import ButtonWithIcon, { CancelButtonWithIcon } from '../../Common/Components/Button'
import styles from '../Auth.module.css'
import cx from 'classnames'
import { useHistory, Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { AppStateType } from '../../../Store/reducers'
import { dispatchLoginCancel } from '../Actions/login.actions'

const SendCode = () => {
  const [radio, setRadio] = useState<'email' | 'phone'>('email')

  const history = useHistory()
  const dispatch = useDispatch()
  const { isAuthenticated } = useSelector((state: AppStateType) => state.AuthReducer)

  if (!isAuthenticated) {
    return <Redirect to="/login" />
  }

  const onSubmit = () => {
    history.push('/login/verifyCode')
  }

  const onCancelClick = () => {
    dispatch(dispatchLoginCancel(false))
  }

  return (
    <div className="row p-0 m-0 m-5">
      <div className="col-md-12 mt-4">
        <p className={styles.createAccountText}>{I18n.t('send_code.title')}</p>
      </div>
      <div className="row p-0 m-0 w-100">
        <div className="col-md-12">
          <Form.Group className={cx(styles.minHeightBox, 'm-0')}>
            <Form.Label className={cx(styles.sendCodeRadioLabel, styles.required)}>
              {I18n.t('send_code.send_code_to')}
            </Form.Label>
            <div key={`inline-radio`} className="mb-3 d-flex justify-content-between">
              <label className={cx(styles.sendCodeRadio, radio === 'email' && styles.sendCodeRadioChecked)}>
                <Form.Check
                  inline
                  type="radio"
                  defaultChecked
                  onClick={() => setRadio('email')}
                  label={I18n.t('send_code.email')}
                />
                <span className="pl-3">{I18n.t('send_code.email_text')}</span>
              </label>
              <label className={cx(styles.sendCodeRadio, radio === 'phone' && styles.sendCodeRadioChecked)}>
                <Form.Check
                  inline
                  disabled
                  onClick={() => setRadio('phone')}
                  type="radio"
                  label={I18n.t('send_code.phone')}
                />
                <span className="pl-3">{I18n.t('send_code.phone_text')}</span>
              </label>
            </div>
          </Form.Group>
        </div>
      </div>
      <div className="row p-0 m-0 mt-3 w-100 d-flex justify-content-center">
        <div className="col-md-6 d-flex justify-content-end">
          <CancelButtonWithIcon
            onClick={onCancelClick}
            type="button"
            text="Cancel"
            x-automation-id="cancel-btn"
            id="cancel-btn"
          />
        </div>
        <div className="col-md-6">
          <ButtonWithIcon
            type="button"
            onClick={onSubmit}
            text={I18n.t('send_code.send_code')}
            icon={<ArrowRight color="#FFFFFF" />}
            iconToRight={true}
            x-automation-id="next-btn"
            id="next-btn"
            className={styles.customButton}
          />
        </div>
      </div>
    </div>
  )
}

export default SendCode
