export const authToast = {
  sign_up_pending: 'Complete el proceso de registro de su cuenta',
  code_resent_success: 'Código reenviado con éxito',
  invalid_code: 'Código ingresado no válido',
  code_expired:
    "Su código de verificación único no es válido o ha caducado. Haga clic en 'Reenviar' para solicitar un nuevo código",
  invalid_invite: 'Código de invitación no válido',
  user_not_exists: 'El usuario no existe',
  captch_expited: 'Google captcha caducó',
  user_already_exists: 'Ya existe una cuenta con este correo electrónico. Por favor, registrese',
  invalid_login:
    'Credenciales de acceso invalidos. Haga clic en Registrarse para registrar una nueva cuenta o haga clic en ¿Olvidó su contraseña para restablecer su contraseña?',
  toomanycall: 'Ha enviado demasiadas solicitudes en un período de tiempo determinado. ¡Inténtalo de nuevo más tarde!',
}

export const register = {
  information: 'Información',
  all_rights_reserved: 'CYTRIO, Inc. Todos los derechos reservados',
  verification_code: 'Código de verificación',
  moreDetails: 'Más sobre ti',
  about: 'Acerca de',
  help: 'Ayuda',
  privacy_policy: 'Política de Privacidad',
  terms_and_conditions: 'Términos de uso',
  data_captured:
    'Los datos capturados durante el registro se utilizarán para brindar acceso al Centro de privacidad, mejorar la experiencia del usuario y brindar servicios suscritos de CYTRIO',
  pwd_must_meet: 'La contraseña debe cumplir',
  following_requirements: 'los siguientes requisitos:',
  one_letter: 'Al menos una letra',
  one_capital: 'Al menos una letra mayúscula',
  one_number: 'Al menos un número',
  eight: 'Tener al menos 8 caracteres',
  create_account: 'Crear cuenta',
  company_name: 'Nombre de la empresa',
  company_name_required: 'Ingrese el nombre de la empresa',
  first_name: 'Nombre',
  first_name_required: 'Por favor ingrese el primer nombre',
  last_name: 'Apellido',
  last_name_required: 'Por favor ingrese apellido',
  email: 'Correo electrónico',
  email_required: 'Ingrese un correo electrónico válido',
  phone: 'Teléfono',
  phone_required: 'Por favor ingrese el teléfono',
  phoneNumberInvalid: 'Número de teléfono no válido',
  password: 'Contraseña',
  country: 'País',
  country_required: 'Por favor ingrese el país',
  state: 'Estado',
  state_required: 'Por favor ingrese el estado',
  password_required: 'Ingrese una contraseña válida',
  confirm_password: 'Confirmar contraseña',
  confirm_password_required: 'Ingrese la contraseña de confirmación',
  confirm_password_not_match: 'La contraseña no coincide',
  agree: 'Estoy de acuerdo con las de CYTRIO',
  terms: 'Términos y condiciones',
  terms_required: 'Acepte los términos y condiciones',
  already_registered: '¿Ya estás registrado?',
  sign_in: 'Iniciar sesión',
  trouble_sign_up: 'Si tiene problemas para registrarse, por favor',
  contact_admin: 'Administrador de contacto',
  captcha_required: 'Verifique el Captcha de Google',
}

export const verifyCode = {
  title: 'Centro de privacidad',
  resend: 'Reenviar',
  sent_to_email: 'Se ha enviado un código de verificación de 6 dígitos a su ID de correo electrónico',
  enter_code: 'Ingrese el código a continuación',
  submit_btn: 'Verificar',
  valid_for: 'Tu código será válido para',
  didnt_receive: '¿No recibiste un código?',
  code_required: 'Por favor ingrese el código',
  valid_code: 'Ingrese solo dígitos',
  mins: 'Minutos',
}

export const sendCode = {
  title: 'Enviar código de verificación',
  send_code_to: 'Enviar código de verificación de 6 dígitos a ',
  send_code: 'Enviar código',
  email: 'Correo electrónico',
  phone: 'Teléfono',
  email_text: 'Empresa...@nombre.com',
  phone_text: '1234-567-890',
}

export const login = {
  login: 'Iniciar sesión',
  sign_in_button: 'Iniciar sesión',
  sign_in: 'Centro de Privacidad',
  sign_in_cred: 'Inicia sesión con tus credenciales',
  sign_up: 'Registrarse',
  email: 'Correo electrónico',
  password: 'Contraseña',
  dont_have_account: '¿No tienes una cuenta?',
  create_account: 'Crear cuenta',
  troubles: 'Si tiene problemas para iniciar sesión, por favor',
  contact_admin: 'Administrador de contacto',
  forgot_password: '¿Olvidó su contraseña?',
  remember_password: '¿Recuerdas la contraseña?',
  data_captured:
    'Los datos capturados durante el inicio de sesión se utilizarán para brindar acceso al Centro de privacidad, mejorar la experiencia del usuario y brindar servicios suscritos desde CYTRIO',
}

export const moreDetails = {
  tellUsMore: 'Cuéntanos más sobre ti',
  dateOfBirth: 'Fecha de nacimiento',
  dateOfBirth_required: 'Ingrese la fecha de nacimiento',
  zipcode: 'Código postal',
  address: 'Dirección',
  city: 'Ciudad',
}

export const forgetPass = {
  forgetPass: '¿Olvidó su contraseña?',
  forgetPassDescription:
    'Ingrese su dirección de correo electrónico y le enviaremos un enlace para restablecer su contraseña',
  emaildidNotFound: 'Esa dirección no es válida o no está asociada con ninguna cuenta',
  backToLogin: 'Volver al inicio de sesión',
  passwordResetEmail: 'Correo electrónico de restablecimiento de contraseña enviado',
  passwordResetEmailContent: 'Se ha enviado un correo electrónico a su dirección de correo electrónico registrada.',
  passwordResetSubContent:
    'Haga clic en el enlace proporcionado en el correo electrónico para restablecer la contraseña.',
  linkExpired: 'Este enlace ha caducado',
  linkExpiredContent: 'Haga clic aquí para enviar un nuevo enlace',
  resendLink: 'Reenviar enlace',
  resetPass: 'Restablecer contraseña',
  resetPassContent: 'Ingrese su nueva contraseña',
  passwordChanged: 'Contraseña modificada',
  passwordChangedSuccess: 'Su contraseña ha sido cambiada exitosamente.',
  newPassword: 'Nueva contraseña',
  newPasswordSameOld: 'La nueva contraseña no puede ser la misma que la anterior (antigua)',
  addressnotfound:'Si el usuario existe, se enviará un correo electrónico.'
}
