export class RgbaColor {
  constructor(private r: number, private g: number, private b: number, private a: number) {}

  setAlpha(alpha: number): RgbaColor {
    return new RgbaColor(this.r, this.g, this.b, alpha)
  }

  toString() {
    return `rgba(${this.r}, ${this.g}, ${this.b}, ${this.a})`
  }
}

export interface ThemeConfig {
  logo: string
  primaryColor: RgbaColor
  secondaryColor: RgbaColor
  fontColor: RgbaColor
  menuStyleColor?: string
  pcvPolicy?: string
  tmsUse?: string
  cntEmail?: string
  textColor?: string
  profileIconColor?: string
}
