import { register, verifyCode, sendCode, login, authToast, moreDetails, forgetPass } from './Auth/fr'
import { request } from './Requests/fr'
import { profileSettings } from './ProfileSettings/fr'
import { LocaleMessagesType } from './en'

export const fr: LocaleMessagesType = {
  header: {
    logout: 'Déconnexion',
    settings: 'Paramètres du profil',
    help: 'Aide',
  },
  sidebar: {
    dsar: 'DSAR',
    tenant_health: 'Santé des locataires',
    application_health: "Santé de l'application",
    dsar_workspace: 'Espace de travail DSAR',
    tenants: 'Locataires',
    users: 'Utilisateurs',
    scanners: 'Scanneurs',
    data_sources: 'Sources de données',
    classifications: 'Classifications',
    privacy_portal: 'Portail de confidentialité',
    system_settings: 'Paramètres système',
    terms_of_service: "Conditions d'utilisation",
  },
  common: {
    send: 'Envoyer',
    on: 'Activé',
    off: 'Désactivé',
    save: 'Enregistrer',
    submit: 'Soumettre',
    cancel: 'Annuler',
    done: 'Fait',
    add: 'Ajouter',
    update: 'Mettre à jour',
    location: 'Emplacement',
    locationRequired: "L'emplacement est requis",
    invalidEmail: "Format d'adresse e-mail invalide",
    invalidUrl: 'Adresse URL invalide',
    toast: {
      something_went_wrong: "Quelque chose s'est mal passé",
      no_results_found: 'Aucun résultat trouvé.',
      jwtTokenExpired: 'Votre session a expiré',
    },
    next: 'Suivant',
    saveAndNext: 'Enregistrer et Suivant',
    back: 'Retour',
    invite: 'Inviter',
    not_found: 'la page que vous avez demandée est introuvable',
    error_boundry_text: "Une erreur s'est produite lors du chargement de cette section.",
    error_boundry_reload: 'Recharger cette page',
    ok: 'OK',
    select: 'sélectionner..',
    showingToOfResults: 'Affichage de %{from} à %{to} sur %{total} résultats',
  },
  profile_settings: profileSettings,
  auth_toast: authToast,
  login: login,
  send_code: sendCode,
  verify_code: verifyCode,
  register: register,
  moreDetails: moreDetails,
  request: request,
  forgetPass: forgetPass,
}
