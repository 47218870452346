import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
import i18nReducer from '../l10n/Reducers/i18reducer'
import { ConfigReducer } from '../Modules/ThemeConfig/Reducers/config.reducers'
import { AuthReducer } from '../Modules/Auth/Reducers/auth.reducers'
import { LoaderReducer } from '../Modules/Loader/Reducers'
import { LocationReducer } from './../Modules/Common/Services/Locations/location.reducer'
import { CitiesReducer } from './../Modules/Common/Services/Cities/cities.reducer'
import { RequestReducer } from './../Modules/Requests/Reducer/request.reducer'
import { ProfileSettingsReducer } from '../Modules/ProfileSettings/Reducers'

const rootReducer = combineReducers({
  LoaderReducer,
  AuthReducer,
  ConfigReducer,
  router: routerReducer,
  i18n: i18nReducer,
  LocationReducer,
  CitiesReducer,
  RequestReducer,
  ProfileSettingsReducer
})

type RootReducerType = typeof rootReducer
export type AppStateType = ReturnType<RootReducerType>

export default rootReducer
