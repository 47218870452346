import { Dispatch } from 'react'
import { Action } from 'redux'
import service from '../Services/forget.password'
import { loaderStart, loaderStop } from '../../Loader/Reducers'
import toastService from '../../Common/Services/Toaster'
import { I18n } from 'react-redux-i18n'
import { ApiResponse } from '../../Common/Models'
import { ResponseCodes } from '../../Common/Enums/Common'

export const ACTION_VERIFY_RESET_PASSWORD_CODE = 'ACTION_VERIFY_RESET_PASSWORD_CODE'
export const ACTION_VERIFY_RESET_PASSWORD_CODE_SUCCESS = 'ACTION_VERIFY_RESET_PASSWORD_CODE_SUCCESS'
export const ACTION_VERIFY_RESET_PASSWORD_CODE_FAILED = 'ACTION_VERIFY_RESET_PASSWORD_CODE_FAILED'

export interface ActionVerifyResetPasswordCode extends Action {
  type: typeof ACTION_VERIFY_RESET_PASSWORD_CODE
}

export interface ActionVerifyResetPasswordCodeSuccess extends Action {
  type: typeof ACTION_VERIFY_RESET_PASSWORD_CODE_SUCCESS
  payload: { data: ApiResponse<string> }
}

export interface ActionVerifyResetPasswordCodeFailed extends Action {
  type: typeof ACTION_VERIFY_RESET_PASSWORD_CODE_FAILED
  payload: {
    data: ApiResponse<string>
  }
}

export type VerifyResetPasswordCodeActions =
  | ActionVerifyResetPasswordCode
  | ActionVerifyResetPasswordCodeFailed
  | ActionVerifyResetPasswordCodeSuccess

export const dispatchVerifyResetPasswordCode = (): ActionVerifyResetPasswordCode => ({
  type: ACTION_VERIFY_RESET_PASSWORD_CODE,
})

const dispatchVerifyResetPasswordCodeSuccess = (data: ApiResponse<string>): ActionVerifyResetPasswordCodeSuccess => ({
  type: ACTION_VERIFY_RESET_PASSWORD_CODE_SUCCESS,
  payload: {
    data,
  },
})

const dispatchVerifyResetPasswordCodeFailed = (data: ApiResponse<string>): ActionVerifyResetPasswordCodeFailed => ({
  type: ACTION_VERIFY_RESET_PASSWORD_CODE_FAILED,
  payload: { data },
})
const toaster = (code: number) => {
  switch (code) {
    case ResponseCodes.InvalidParams:
      toastService.error(I18n.t('auth_toast.invalid_code'))
      break
    case ResponseCodes.NotFound:
      toastService.error(I18n.t('auth_toast.user_not_exists'))
      break
    case ResponseCodes.ExpiredCode:
      //Nothing to do
      break
    default:
      toastService.error(I18n.t('common.toast.something_went_wrong'))
      break
  }
}

export function actionVerifyResetPasswordCode(
  code: string,
  tenantUrl: string
): (dispatch: Dispatch<VerifyResetPasswordCodeActions>) => Promise<void> {
  return async (dispatch: Dispatch<VerifyResetPasswordCodeActions>) => {
    dispatch(dispatchVerifyResetPasswordCode())
    loaderStart(dispatch)
    try {
      const result = await service.verifyPasswordLink(code, tenantUrl)
      if (result && result.success) {
        dispatch(dispatchVerifyResetPasswordCodeSuccess(result))
      } else {
        toaster(result?.statusCode)
        dispatch(dispatchVerifyResetPasswordCodeFailed(result))
      }
      loaderStop(dispatch)
    } catch (error) {
      let result: ApiResponse<string> = {
        message: error,
        success: false,
      }
      dispatch(dispatchVerifyResetPasswordCodeFailed(result))
      loaderStop(dispatch)
    }
  }
}
