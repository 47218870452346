import { auth } from '../../Common/Constants/ApiUrls'
import { SessionStorageKeyNames } from '../../Common/Enums'
import { SessionStorage } from '../../../Utils/SessionStorageWrapper'
import { formatDateByFormat } from '../../../Utils/dateAndTimeUtils'
import { http } from '../../../Utils/AxiosWrapper'

export interface RegisterRequest {
  email: string
  password: string
  firstName: string
  lastName: string
  companyName: string
  phone: string
  inviteCode: string
  tenantUrl: string
  zipcode: string
  dateOfBirth: string
  address: string
  city: string
  state: string
  country: string
  phone_code?: number
}

export interface RegisterResponse {
  success: boolean
  message: string
  code: number
}

export interface RegisterService {
  register(request: RegisterRequest): Promise<RegisterResponse>
}

class RegisterServiceDefault implements RegisterService {
  async register(request: RegisterRequest): Promise<RegisterResponse> {
    const bodyJSONData = {
      email: request.email,
      password: request.password,
      firstname: request.firstName,
      lastname: request.lastName,
      phone: request.phone,
      phone_code: request.phone_code,
      zip_code: request.zipcode,
      birth_date: request.dateOfBirth ? formatDateByFormat(new Date(request.dateOfBirth)) : '',
      address: request.address,
      city: request.city,
      state: request.state,
      country: request.country,
      'g-recaptcha-response': request.inviteCode,
    }
    let result: RegisterResponse
    try {
      const { data } = await http.post(auth.register(request.tenantUrl), bodyJSONData)
      result = RegisterServiceDefault.buildResponse(data)
    } catch (error) {
      result = {
        success: false,
        message: error.response.data.message,
        code: error.response.data.code,
      }
    }

    return result
  }

  static buildResponse(data: any): RegisterResponse {
    if (!data || !data.jwt_token) {
      return {
        success: false,
        message: '',
        code: 404,
      }
    }

    SessionStorage.setKey(SessionStorageKeyNames.TempToken, data.jwt_token.token)
    SessionStorage.setKey(SessionStorageKeyNames.TempTokenExpiry, data.jwt_token.exp_time_in_minutes)

    return {
      success: true,
      message: data.message,
      code: data.code,
    }
  }
}

export default new RegisterServiceDefault()
