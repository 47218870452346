import { Dispatch } from 'react'
import { Action } from 'redux'
import { I18n } from 'react-redux-i18n'
import toaster from '../../Common/Services/Toaster'
import { AddRequest } from '../Models'
import service from '../Service/request.service'
import { ApiResponse } from '../../Common/Models'
import { ResponseCodes } from '../../Common/Enums/Common'
import { loaderStart, loaderStop } from '../../Loader/Reducers'

export const ACTION_ADD_REQUEST_START = 'ACTION_ADD_REQUEST_START'
export const ACTION_ADD_REQUEST_SUCCESS = 'ACTION_ADD_REQUEST_SUCCESS'
export const ACTION_ADD_REQUEST_FAILURE = 'ACTION_ADD_REQUEST_FAILURE'
export const ACTION_ADD_REQUEST_CLEAR = 'ACTION_ADD_REQUEST_CLEAR'

export interface ActionAddRequestStart extends Action {
  type: typeof ACTION_ADD_REQUEST_START
  payload: {
    request: AddRequest
  }
}

export interface ActionAddRequestSuccess extends Action {
  type: typeof ACTION_ADD_REQUEST_SUCCESS
  payload: {
    data: ApiResponse<string>
  }
}

export interface ActionAddRequestFailure extends Action {
  type: typeof ACTION_ADD_REQUEST_FAILURE
  payload: {
    success: boolean
    errorMessage: string
  }
}

export interface ActionAddRequestClear extends Action {
  type: typeof ACTION_ADD_REQUEST_CLEAR
  payload: {}
}

export type AddRequestActions =
  | ActionAddRequestStart
  | ActionAddRequestSuccess
  | ActionAddRequestFailure
  | ActionAddRequestClear

const dispatchAddRequestSuccess = (data: ApiResponse<string>): ActionAddRequestSuccess => ({
  type: ACTION_ADD_REQUEST_SUCCESS,
  payload: { data },
})

const dispatchAddRequestFailure = (err: Error, success: boolean): ActionAddRequestFailure => ({
  type: ACTION_ADD_REQUEST_FAILURE,
  payload: {
    errorMessage: err.message,
    success,
  },
})

export const dispatchRequestClear = (): ActionAddRequestClear => ({
  type: ACTION_ADD_REQUEST_CLEAR,
  payload: {},
})

export function actionAddRequest(
  addRequest: AddRequest,
  tenantUrl: string
): (dispatch: Dispatch<AddRequestActions>) => Promise<void> {
  return async (dispatch: Dispatch<AddRequestActions>) => {
    try {
      loaderStart(dispatch)
      const result = await service.addRequest(addRequest, tenantUrl)
      if (result.statusCode === ResponseCodes.CreatedSuccessfully) {
        dispatch(dispatchAddRequestSuccess(result))
        toaster.success(I18n.t('request.requestSubmit'))
      } else {
        if (result.statusCode === ResponseCodes.ConflictsRequest) {
          toaster.error(I18n.t('request.duplicateRequest'))
        } else if (result.statusCode !== ResponseCodes.Unauthorized) {
          toaster.error(I18n.t('common.toast.something_went_wrong'))
        }
        dispatch(dispatchAddRequestFailure(new Error(result.message), false))
      }
      loaderStop(dispatch)
    } catch (err) {
      loaderStop(dispatch)
      toaster.error(I18n.t('common.toast.something_went_wrong'))
      dispatch(dispatchAddRequestFailure(err, false))
    }
  }
}
