import { auth } from '../../Common/Constants/ApiUrls'
import { UserModel } from '../Models/user.model'
import { http } from '../../../Utils/AxiosWrapper'

export interface UserDetailRepsonse {
  success: boolean
  message: string
  code: number
  data?: UserModel
}

export interface UserDetailsService {
  getUserDetails(tenantUrl: string): Promise<UserDetailRepsonse>
}

class UserDetailsServiceDefault implements UserDetailsService {
  async getUserDetails(tenantUrl: string): Promise<UserDetailRepsonse> {
    let result: UserDetailRepsonse
    try {
      const { data } = await http.get(auth.userProfile(tenantUrl))
      result = UserDetailsServiceDefault.buildResponse(data, tenantUrl)
    } catch (error: any) {
      result = {
        success: false,
        message: error.response.data.message,
        code: error.response.data.code,
      }
    }

    return result
  }

  static buildResponse(data: any, tenantUrl: string): UserDetailRepsonse {
    if (!data) {
      return {
        success: false,
        code: 4000,
        message: 'Empty user data in response',
      }
    }

    const user: UserModel = {
      address: data.data.address,
      dateOfBirth: data.data.birth_date,
      city: data.data.city,
      country: data.data.state,
      email: data.data.email,
      firstName: data.data.firstname,
      lastName: data.data.lastname,
      phone: data.data.phone,
      state: data.data.state,
      status: data.data.status,
      zipcode: data.data.zip_code,
      tenantUrl: tenantUrl,
      tenantId: data.data.tenant_id,
      pcUserId: data.data.pc_user_id,
    }

    return {
      success: true,
      message: data.message,
      code: data.code,
      data: user,
    }
  }
}

export default new UserDetailsServiceDefault()
