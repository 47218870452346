export const profileSettings = {
  toast: {
    wrong_current_password: 'Ungültiges aktuelles Passwort',
    change_password_success: 'Passwort erfolgreich aktualisiert',
  },
  title: 'Profileinstellungen',
  profile_picture: 'Profilbild',
  profile_details: 'Profildetails',
  change_your_password: 'Ändere dein Passwort',
  first_name: 'Vorname',
  first_name_required: 'Bitte Vornamen eingeben',
  last_name: 'Nachname',
  last_name_required: 'Bitte Nachnamen eingeben',
  email: 'Geschäftliche E-Mail',
  email_required: 'Bitte E-Mail eingeben',
  phone: 'Telefon',
  phone_required: 'Bitte Telefonnummer eingeben',
  current_password: 'Aktuelles Passwort',
  current_password_required: 'Bitte aktuelles Passwort eingeben',
  new_password_cant_same: 'Neues Passwort und aktuelles Passwort dürfen nicht gleich sein',
  new_password: 'Neues Passwort',
  new_password_required: 'Bitte geben Sie ein gültiges neues Passwort ein',
  confirm_password: 'Passwort bestätigen',
  confirm_password_required: 'Neues Passwort bestätigen',
  confirm_password_not_match: 'Passwort stimmt nicht überein',
  change_photo: 'Foto ändern',
  delete: 'Löschen',
}
