export const request = {
  open: 'Abierto',
  close: 'Cerrado',
  completed: 'Completado',
  error: 'Error',
  inProgress: 'En curso',
  dsar: 'Solicitudes de acceso a datos',
  iam: 'soy un',
  customer: 'Cliente',
  employee: 'Empleado',
  userTypeRequired: 'Se requiere el tipo de usuario',
  chooseRequestType: 'Elegir tipo de solicitud',
  requestTypeRequired: 'Se requiere el tipo de solicitud',
  rightToAccess: 'Derecho de acceso',
  rightToAccessDescription: 'Le enviaremos un informe completo con los datos que tenemos sobre usted',
  rightToDelete: 'Derecho a eliminar',
  rightToDeleteDescription:
    'Le proporcionaremos un informe completo de sus datos personales que eliminamos de nuestros sistemas',
  rightToDoNotSell: 'No vender',
  rightToDoNotSellDescription: 'Dejaremos de compartir/vender su información personal a cualquiera de nuestros socios publicitarios.',
  submitRequest: 'Enviar peticion',
  submitRequestDescription: 'Proporcione la siguiente información para enviar la solicitud',
  duplicateRequest: 'Ya hay una solicitud similar en proceso. Seleccione un tipo de solicitud diferente',
  requestSubmit: 'Solicitud enviada',
  noDataList: 'No existe solicitud de acceso a datos',
  noDataListSubHeadig: 'Enviar solicitud para continuar',
  requestType: 'Tipo de solicitud',
  requestId: 'Solicitud de identificación',
  submitDate: 'Fecha de envío',
  status: 'estado',
  completedDate: 'Fecha de finalización',
  downeloadReport: 'Descargar informe',
  downloadZip: 'Descargar Zip',
  searchBy: 'Buscar por ID de solicitud',
  submitReqDescription:
    'Nos comprometemos a respetar sus derechos de privacidad y los datos personales que comparte con nosotros. Como parte de ese compromiso, el Centro de privacidad lo pone a usted a cargo de sus datos y nos ayuda a cumplir de manera eficiente con sus solicitudes de privacidad de datos. Tiene derecho a obtener una copia de sus datos y derecho a eliminar sus datos.',
}
