import { UserModel } from '../Models/user.model'
import { auth } from '../../Common/Constants/ApiUrls'
import { http } from '../../../Utils/AxiosWrapper'
export interface VerifyCodeRequest {
  email: string
  code: string
  tenantUrl: string
}

export interface VerifyCodeResponse {
  success: boolean
  message: string
  code: number
  user?: UserModel
}

export interface VerifyCodeService {
  verifyCode(request: VerifyCodeRequest): Promise<VerifyCodeResponse>
}

class VerifyCodeServiceDefault implements VerifyCodeService {
  async verifyCode(request: VerifyCodeRequest): Promise<VerifyCodeResponse> {
    const bodyJSONData = {
      email: request.email,
      mfa_code: request.code,
    }
    let result: VerifyCodeResponse
    try {
      const { data } = await http.post(auth.verifyCode(request.tenantUrl), bodyJSONData)
      result = VerifyCodeServiceDefault.buildResponse(data)
    } catch (error) {
      result = {
        success: false,
        message: error.response.data.message,
        code: error.response.data.code,
      }
    }

    return result
  }

  static buildResponse(data: any): VerifyCodeResponse {
    if (!data) {
      return {
        success: false,
        code: 4000,
        message: 'Empty verify response',
      }
    }

    return {
      success: true,
      message: data.message,
      code: data.code,
    }
  }
}

export default new VerifyCodeServiceDefault()
