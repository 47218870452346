import { register, verifyCode, sendCode, login, authToast, moreDetails, forgetPass } from './Auth/es'
import { request } from './Requests/es'
import { profileSettings } from './ProfileSettings/es'
import { LocaleMessagesType } from './en'

export const es: LocaleMessagesType = {
  header: {
    logout: 'Cerrar sesión',
    settings: 'Configuración de perfil',
    help: 'Ayuda',
  },
  sidebar: {
    dsar: 'DSAR',
    tenant_health: 'Salud del inquilino',
    application_health: 'Salud de la aplicación',
    dsar_workspace: 'Espacio de trabajo DSAR',
    tenants: 'Inquilinos',
    users: 'Usuarios',
    scanners: 'Escáneres',
    data_sources: 'Fuentes de datos',
    classifications: 'Clasificaciones',
    privacy_portal: 'Portal de privacidad',
    system_settings: 'Configuración del sistema',
    terms_of_service: 'Términos de servicio',
  },
  common: {
    send: 'Enviar',
    on: 'Encendido',
    off: 'Apagado',
    save: 'Guardar',
    submit: 'Enviar',
    cancel: 'Cancelar',
    done: 'Hecho',
    add: 'Agregar',
    update: 'Actualizar',
    location: 'Ubicación',
    locationRequired: 'Se requiere la ubicación',
    invalidEmail: 'Formato de dirección de correo electrónico no válido',
    invalidUrl: 'Dirección URL no válida',
    toast: {
      something_went_wrong: 'Algo salió mal',
      no_results_found: 'No se encontraron resultados.',
      jwtTokenExpired: 'Su sesión ha expirado',
    },
    next: 'Siguiente',
    saveAndNext: 'Guardar y Siguiente',
    back: 'Atrás',
    invite: 'Invitar',
    not_found: 'la página que solicitó, no se pudo encontrar',
    error_boundry_text: 'Hubo un error al cargar esta sección.',
    error_boundry_reload: 'Recargar esta página',
    ok: 'OK',
    select: 'Seleccione..',
    showingToOfResults: 'Mostrando %{from} a %{to} de %{total} resultados',
  },
  profile_settings: profileSettings,
  auth_toast: authToast,
  login: login,
  send_code: sendCode,
  verify_code: verifyCode,
  register: register,
  moreDetails: moreDetails,
  request: request,
  forgetPass: forgetPass,
}
