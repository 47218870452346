import {
  ACTION_CHANGE_PASSWORD,
  ACTION_CHANGE_PASSWORD_FAILED,
  ACTION_CHANGE_PASSWORD_SUCCESS,
  ChangePasswordActions,
} from '../Actions/change-password.actions'

export interface ProfileSettingsReducerState {
  changePassword: {
    errorMessage: string
    success: boolean
  }
}

export const initialState: ProfileSettingsReducerState = {
  changePassword: {
    errorMessage: '',
    success: false,
  },
}

export const ProfileSettingsReducer = (
  state = initialState, //NOSONAR
  action: ChangePasswordActions
): ProfileSettingsReducerState => {
  switch (action.type) {
    case ACTION_CHANGE_PASSWORD:
      return {
        ...state,
        changePassword: {
          success: false,
          errorMessage: '',
        },
      }
    case ACTION_CHANGE_PASSWORD_FAILED:
      return {
        ...state,
        changePassword: {
          success: false,
          errorMessage: action.payload.errorMessage,
        },
      }
    case ACTION_CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePassword: {
          success: true,
          errorMessage: '',
        },
      }
    default:
      return state
  }
}
