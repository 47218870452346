import moment from 'moment'
export interface CytrioDateFormat {
  fullYear: string
  month: string
  date: string
  hour: string
  minute: string
  hourSuffix: string
  fullMonthName: string
  shortMonthName: string,
  shortYear: string
  seconds: string
}

function buildCytrioDateFormat(someDate: Date): CytrioDateFormat {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  const shortMonthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  const date = someDate.getDate()
  const month = someDate.getMonth() + 1
  const fullYear = someDate.getFullYear().toString()
  const shortYear = someDate.getFullYear().toString().substr(-2)
  const fullMonthName = monthNames[someDate.getMonth()]
  const shortMonthName = shortMonthNames[someDate.getMonth()]
  let seconds = `${someDate.getSeconds()}`
  if (someDate.getSeconds() < 10) {
    seconds = `0${seconds}`
  }
  let minute = `${someDate.getMinutes()}`
  if (someDate.getMinutes() < 10) {
    minute = `0${minute}`
  }
  let normalizeHour
  const hours = someDate.getHours()
  if (hours >= 13) {
    normalizeHour = hours - 12
  } else {
    normalizeHour = hours
  }

  if (normalizeHour === 0) {
    normalizeHour = 12
  }

  return {
    fullYear,
    month: month < 10 ? `0${month}` : `${month}`,
    date: date < 10 ? `0${date}` : `${date}`,
    hour: `${normalizeHour}`,
    minute,
    hourSuffix: hours >= 12 ? 'PM' : 'AM',
    fullMonthName,
    shortMonthName,
    shortYear,
    seconds,
  }
}

export const formatDefaultDate = (someDate: Date): string => {
  if (!someDate) {
    return ''
  }
  const { shortMonthName, date, fullYear } = buildCytrioDateFormat(someDate)
  return `${shortMonthName} ${date}, ${fullYear}`
}

export const formatDefaultFullDate = (someDate: Date, isWithSeconds?: boolean): string => {
  const { shortMonthName, date, fullYear, hour, hourSuffix, minute, seconds } = buildCytrioDateFormat(someDate)
  if (isWithSeconds) {
    return `${shortMonthName} ${date}, ${fullYear} ${hour}:${minute}:${seconds} ${hourSuffix}`
  }
  return `${shortMonthName} ${date}, ${fullYear} ${hour}:${minute} ${hourSuffix}`
}


export const formatDateByFormat = (someDate: Date): string => {
  if (!someDate) {
    return ''
  }
  const { month, date, fullYear } = buildCytrioDateFormat(someDate)
  return `${fullYear}-${month}-${date}`
}

export const millisToMinutesAndSeconds = (millis: number) => {
  var minutes = Math.floor(millis / 60000)
  var seconds = ((millis % 60000) / 1000).toFixed(0)
  return minutes + ':' + (parseInt(seconds) < 10 ? '0' : '') + seconds
}

export const getCurrentYear = () => {
  return new Date().getFullYear()
}
