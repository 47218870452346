import React, { useEffect, useState } from 'react'
import { I18n } from 'react-redux-i18n'
import cx from 'classnames'
import { ArrowRight, CheckCircle } from 'react-feather'
import ButtonWithIcon, { CancelButtonWithIcon } from '../Common/Components/Button'
import styles from './Auth.module.css'
import { useSelector } from 'react-redux'
import { AppStateType } from '../../Store/reducers'
import { loadEnvironmentVariables } from 'Config'

export const bodyChildContent = (isPasswordChangePopup: boolean) => (
  <>
    <div className="col-md-12 d-flex justify-content-center">
      <CheckCircle height={40} width={40} color="#009120" />
    </div>
    <div className="col-md-12 mt-2 text-center">
      <strong className={styles.sentPopupHeading}>
        {isPasswordChangePopup ? I18n.t('forgetPass.passwordChanged') : I18n.t('forgetPass.passwordResetEmail')}
      </strong>
    </div>
    <div className={cx('col-md-12 mt-2 text-center', styles.sentPopupDescription)}>
      {isPasswordChangePopup
        ? I18n.t('forgetPass.passwordChangedSuccess')
        : I18n.t('forgetPass.passwordResetEmailContent')}
    </div>{' '}
  </>
)

interface CommonFooterProps {
  onCancelClick: () => void
  isRegisterForm: boolean
}

export const CommonFooter = ({ onCancelClick, isRegisterForm }: CommonFooterProps) => {
  const [envVar, setEnvVar] = useState<any>()
  const themeConfig = useSelector((state: AppStateType) => state.ConfigReducer.themeConfig)

  useEffect(() => {
    loadEnvironmentVariables().then(data => {
      setEnvVar(data)
    })
  }, [])

  return (
    <>
      <div className={cx(isRegisterForm ? 'mt-1' : 'mt-3', 'row p-0 m-0 w-100 d-flex justify-content-center')}>
        <div className={'col-md-4'}>
          <ButtonWithIcon
            type="submit"
            text={I18n.t('common.saveAndNext')}
            x-automation-id="next-btn"
            id="next-btn"
            icon={<ArrowRight color="#FFFFFF" size={20} />}
            iconToRight={true}
          />
        </div>
      </div>
      <div className={cx(isRegisterForm ? 'mt-2' : 'mt-3', 'row w-100 d-flex justify-content-center')}>
        <p className={styles.alreadyRegisteredText}>
          {I18n.t('register.trouble_sign_up')}{' '}
          <a
            href={'mailto:' + themeConfig?.cntEmail}
            x-automation-id="contact-admin"
            id="contact-admin"
            className={styles.linkText}
          >
            {I18n.t('register.contact_admin')}
          </a>
        </p>
      </div>
    </>
  )
}
