import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'
import styles from '../Auth.module.css'
import cx from 'classnames'
import queryString from 'querystring'
import { ErrorMessage } from '@hookform/error-message'
import Select, { components, SingleValueProps } from 'react-select'
import { useForm, Controller } from 'react-hook-form'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom'
import { AppStateType } from '../../../Store/reducers'
import { emailRegex, phoneNumber } from '../../../Modules/Common/Constants'
import { getLocationDropdownAction } from '../../Common/Services/Locations/location.actions'
import PasswordInput, { validatePasswordInput } from 'Modules/Common/Components/PasswordInput'
import { CommonFooter } from '../Common'
import { actionGetInviteInfo } from '../Actions/invite-info.actions'
import convertToString from 'Utils/queryFormat'
import { LocationDropdownModel } from 'Modules/Common/Models'
import * as Icons from 'Assets/Images/Icons/Flags'
import LanguageChooser from 'Modules/Common/Components/LanguageChooser'
import { getEnvironmentVariables } from 'Config'
import ReCAPTCHA from 'react-google-recaptcha'
import { actionRegister } from '../Actions/register.actions'

const SingleValue = ({ children, ...props }: SingleValueProps<LocationDropdownModel>) => {
  return (
    <components.SingleValue {...props}>
      {children}
      {Icons && Icons[props?.data?.iso] && (
        <img
          style={{
            width: '18px',
            height: '18px',
            marginLeft: '10px',
          }}
          src={Icons[props?.data?.iso]}
        />
      )}
    </components.SingleValue>
  )
}

interface RegisterFormProps {
  setShowMoreDetails: (value: boolean) => void
  showMoreDetails: boolean
}

const RegisterForm = ({ setShowMoreDetails, showMoreDetails }: RegisterFormProps) => {
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    control,
    watch,
    formState,
    formState: { errors },
    clearErrors,
  } = useForm()
  const watchCountryField = watch('country')
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const [locationsDDL, setLocationsDDL] = useState<Array<LocationDropdownModel>>([])
  const [countryMobileCodeList, setCountryMobileCodeList] = useState<Array<LocationDropdownModel>>([])
  const [statesList, setStatesList] = useState([])
  const [hashCode, setHashCode] = useState<string>('')
  const [isResetFromStore, setIsResetFromStore] = useState(false)
  const { isAuthenticated, inviteInfo, signUpDetails } = useSelector((state: AppStateType) => state.AuthReducer)
  const { tenantUrl, themeConfig } = useSelector((state: AppStateType) => state.ConfigReducer)
  const envVars = getEnvironmentVariables()
  const getLocations = useSelector(
    (state: AppStateType) => ({ ...state.LocationReducer.locationDropdown }),
    shallowEqual
  )

  useEffect(() => {
   setValue('country',{
    "value": "USA",
    "label": "United States",
    "countryMobileCode": "+1",
    "iso": "US",
    "states": [
        {
            "value": "AL",
            "label": "Alabama",
            "country": "United States",
            "countryISO": "USA"
        },
        {
            "value": "AK",
            "label": "Alaska",
            "country": "United States",
            "countryISO": "USA"
        },
        {
            "value": "AS",
            "label": "American Samoa",
            "country": "United States",
            "countryISO": "USA"
        },
        {
            "value": "AZ",
            "label": "Arizona",
            "country": "United States",
            "countryISO": "USA"
        },
        {
            "value": "AR",
            "label": "Arkansas",
            "country": "United States",
            "countryISO": "USA"
        },
        {
            "value": "UM-81",
            "label": "Baker Island",
            "country": "United States",
            "countryISO": "USA"
        },
        {
            "value": "CA",
            "label": "California",
            "country": "United States",
            "countryISO": "USA"
        },
        {
            "value": "CO",
            "label": "Colorado",
            "country": "United States",
            "countryISO": "USA"
        },
        {
            "value": "CT",
            "label": "Connecticut",
            "country": "United States",
            "countryISO": "USA"
        },
        {
            "value": "DE",
            "label": "Delaware",
            "country": "United States",
            "countryISO": "USA"
        },
        {
            "value": "DC",
            "label": "District of Columbia",
            "country": "United States",
            "countryISO": "USA"
        },
        {
            "value": "FL",
            "label": "Florida",
            "country": "United States",
            "countryISO": "USA"
        },
        {
            "value": "GA",
            "label": "Georgia",
            "country": "United States",
            "countryISO": "USA"
        },
        {
            "value": "GU",
            "label": "Guam",
            "country": "United States",
            "countryISO": "USA"
        },
        {
            "value": "HI",
            "label": "Hawaii",
            "country": "United States",
            "countryISO": "USA"
        },
        {
            "value": "UM-84",
            "label": "Howland Island",
            "country": "United States",
            "countryISO": "USA"
        },
        {
            "value": "ID",
            "label": "Idaho",
            "country": "United States",
            "countryISO": "USA"
        },
        {
            "value": "IL",
            "label": "Illinois",
            "country": "United States",
            "countryISO": "USA"
        },
        {
            "value": "IN",
            "label": "Indiana",
            "country": "United States",
            "countryISO": "USA"
        },
        {
            "value": "IA",
            "label": "Iowa",
            "country": "United States",
            "countryISO": "USA"
        },
        {
            "value": "UM-86",
            "label": "Jarvis Island",
            "country": "United States",
            "countryISO": "USA"
        },
        {
            "value": "UM-67",
            "label": "Johnston Atoll",
            "country": "United States",
            "countryISO": "USA"
        },
        {
            "value": "KS",
            "label": "Kansas",
            "country": "United States",
            "countryISO": "USA"
        },
        {
            "value": "KY",
            "label": "Kentucky",
            "country": "United States",
            "countryISO": "USA"
        },
        {
            "value": "UM-89",
            "label": "Kingman Reef",
            "country": "United States",
            "countryISO": "USA"
        },
        {
            "value": "LA",
            "label": "Louisiana",
            "country": "United States",
            "countryISO": "USA"
        },
        {
            "value": "ME",
            "label": "Maine",
            "country": "United States",
            "countryISO": "USA"
        },
        {
            "value": "MD",
            "label": "Maryland",
            "country": "United States",
            "countryISO": "USA"
        },
        {
            "value": "MA",
            "label": "Massachusetts",
            "country": "United States",
            "countryISO": "USA"
        },
        {
            "value": "MI",
            "label": "Michigan",
            "country": "United States",
            "countryISO": "USA"
        },
        {
            "value": "UM-71",
            "label": "Midway Atoll",
            "country": "United States",
            "countryISO": "USA"
        },
        {
            "value": "MN",
            "label": "Minnesota",
            "country": "United States",
            "countryISO": "USA"
        },
        {
            "value": "MS",
            "label": "Mississippi",
            "country": "United States",
            "countryISO": "USA"
        },
        {
            "value": "MO",
            "label": "Missouri",
            "country": "United States",
            "countryISO": "USA"
        },
        {
            "value": "MT",
            "label": "Montana",
            "country": "United States",
            "countryISO": "USA"
        },
        {
            "value": "UM-76",
            "label": "Navassa Island",
            "country": "United States",
            "countryISO": "USA"
        },
        {
            "value": "NE",
            "label": "Nebraska",
            "country": "United States",
            "countryISO": "USA"
        },
        {
            "value": "NV",
            "label": "Nevada",
            "country": "United States",
            "countryISO": "USA"
        },
        {
            "value": "NH",
            "label": "New Hampshire",
            "country": "United States",
            "countryISO": "USA"
        },
        {
            "value": "NJ",
            "label": "New Jersey",
            "country": "United States",
            "countryISO": "USA"
        },
        {
            "value": "NM",
            "label": "New Mexico",
            "country": "United States",
            "countryISO": "USA"
        },
        {
            "value": "NY",
            "label": "New York",
            "country": "United States",
            "countryISO": "USA"
        },
        {
            "value": "NC",
            "label": "North Carolina",
            "country": "United States",
            "countryISO": "USA"
        },
        {
            "value": "ND",
            "label": "North Dakota",
            "country": "United States",
            "countryISO": "USA"
        },
        {
            "value": "MP",
            "label": "Northern Mariana Islands",
            "country": "United States",
            "countryISO": "USA"
        },
        {
            "value": "OH",
            "label": "Ohio",
            "country": "United States",
            "countryISO": "USA"
        },
        {
            "value": "OK",
            "label": "Oklahoma",
            "country": "United States",
            "countryISO": "USA"
        },
        {
            "value": "OR",
            "label": "Oregon",
            "country": "United States",
            "countryISO": "USA"
        },
        {
            "value": "UM-95",
            "label": "Palmyra Atoll",
            "country": "United States",
            "countryISO": "USA"
        },
        {
            "value": "PA",
            "label": "Pennsylvania",
            "country": "United States",
            "countryISO": "USA"
        },
        {
            "value": "PR",
            "label": "Puerto Rico",
            "country": "United States",
            "countryISO": "USA"
        },
        {
            "value": "RI",
            "label": "Rhode Island",
            "country": "United States",
            "countryISO": "USA"
        },
        {
            "value": "SC",
            "label": "South Carolina",
            "country": "United States",
            "countryISO": "USA"
        },
        {
            "value": "SD",
            "label": "South Dakota",
            "country": "United States",
            "countryISO": "USA"
        },
        {
            "value": "TN",
            "label": "Tennessee",
            "country": "United States",
            "countryISO": "USA"
        },
        {
            "value": "TX",
            "label": "Texas",
            "country": "United States",
            "countryISO": "USA"
        },
        {
            "value": "UM",
            "label": "United States Minor Outlying Islands",
            "country": "United States",
            "countryISO": "USA"
        },
        {
            "value": "VI",
            "label": "United States Virgin Islands",
            "country": "United States",
            "countryISO": "USA"
        },
        {
            "value": "UT",
            "label": "Utah",
            "country": "United States",
            "countryISO": "USA"
        },
        {
            "value": "VT",
            "label": "Vermont",
            "country": "United States",
            "countryISO": "USA"
        },
        {
            "value": "VA",
            "label": "Virginia",
            "country": "United States",
            "countryISO": "USA"
        },
        {
            "value": "UM-79",
            "label": "Wake Island",
            "country": "United States",
            "countryISO": "USA"
        },
        {
            "value": "WA",
            "label": "Washington",
            "country": "United States",
            "countryISO": "USA"
        },
        {
            "value": "WV",
            "label": "West Virginia",
            "country": "United States",
            "countryISO": "USA"
        },
        {
            "value": "WI",
            "label": "Wisconsin",
            "country": "United States",
            "countryISO": "USA"
        },
        {
            "value": "WY",
            "label": "Wyoming",
            "country": "United States",
            "countryISO": "USA"
        }
    ]
  })
  }, [])

  useEffect(() => {
    dispatch(getLocationDropdownAction(tenantUrl))
  }, [setValue, dispatch])

  useEffect(() => {
    if (!signUpDetails) {
      return
    }
    setIsResetFromStore(true)
    setValue('email', signUpDetails.email)
    setValue('firstName', signUpDetails.firstName)
    setValue('lastName', signUpDetails.lastName)
    if (signUpDetails.country) {
      let countryItem = locationsDDL.find(item => item?.value === signUpDetails.country)
      setValue('country', countryItem)
    }

    if (signUpDetails.state && statesList && statesList.length > 0) {
      let stateItem = statesList.find(item => item?.label === signUpDetails.state)
      setValue('state', stateItem)
    }
    let newPhoneCode
    if (signUpDetails?.phone_code) {
      if (signUpDetails?.phone_code?.includes('+')) {
        newPhoneCode = signUpDetails?.phone_code
      } else {
        newPhoneCode = '+' + signUpDetails?.phone_code
      }
    }
    let findCountryMobileCodeItem = countryMobileCodeList.find(item => item?.value === newPhoneCode)
    let usCountryMobileCodeItem = countryMobileCodeList.find(item => item?.iso === 'US')
    if (findCountryMobileCodeItem) {
      setValue('countryMobileCode', findCountryMobileCodeItem)
    } else if (usCountryMobileCodeItem) {
      setValue('countryMobileCode', usCountryMobileCodeItem)
    }

    if (signUpDetails?.phone) {
      setValue('phone', signUpDetails?.phone)
    }

    if (signUpDetails?.confirmPassword) {
      setValue('confirmPassword', signUpDetails?.confirmPassword)
    }
    if (signUpDetails?.password) {
      setValue('password', signUpDetails?.password)
    }

    if (signUpDetails?.agree) {
      setValue('agree', signUpDetails?.agree)
    }

    if (signUpDetails && locationsDDL.length > 0 && countryMobileCodeList.length > 0) {
      // Added this hack to set state field info from redux store when user click on cancel. 
      setTimeout(() => { setIsResetFromStore(false) }, 1000)
    }

  }, [signUpDetails, locationsDDL, countryMobileCodeList])


  useEffect(() => {
    if (watchCountryField?.value) {
      setStatesList(watchCountryField.states)
      if (!signUpDetails?.phone_code) {
        setValue('countryMobileCode', {
          value: watchCountryField.countryMobileCode,
          label: watchCountryField.countryMobileCode,
          iso: watchCountryField.iso,
        })
      }
    } else {
      setStatesList(undefined)
    }
    if (!isResetFromStore) {
      setValue('state', null)
    }

  }, [watchCountryField])

  useEffect(() => {
    if (getLocations.data) {
      let countryList = getLocations.data.map(countryItem => {
        return {
          value: countryItem?.value,
          label: countryItem?.label,
          countryMobileCode: countryItem?.countryMobileCode,
          iso: countryItem?.iso,
          states: countryItem?.states,
        }
      })
      let countryMobileCodeList = getLocations.data.map(countryItem => {
        return {
          value: countryItem?.countryMobileCode,
          label: countryItem?.countryMobileCode,
          iso: countryItem?.iso,
        }
      })
      setCountryMobileCodeList(countryMobileCodeList)
      setLocationsDDL(countryList)
    }
  }, [getLocations])

  useEffect(() => {
    if (!hashCode) {
      return
    }
    dispatch(actionGetInviteInfo(hashCode, history, tenantUrl))
  }, [hashCode, dispatch, history])

  useEffect(() => {
    if (!inviteInfo) {
      return
    }
    setValue('email', inviteInfo.email)
    setValue('firstName', inviteInfo.firstName)
    setValue('lastName', inviteInfo.lastName)
  }, [inviteInfo, setValue])

  useEffect(() => {
    const { registration_id: registrationId } = queryString.parse(location?.search?.substr(1))
    if (registrationId) {
      setHashCode(convertToString(registrationId))
    }
  }, [location?.search, setHashCode, history])

  if (isAuthenticated) {
    return <Redirect to="/register/verifyCode" />
  }

  const onSubmit = (data: any) => {
    dispatch(
      actionRegister({
        ...data,
        state: data && data.state && data.state.label ? data.state.label : '',
        city: data && data.city && data.city.label ? data.city.label : '',
        phone_code: data?.countryMobileCode?.value ? data.countryMobileCode.value.split('+')[1] : '',
        country: data && data.country && data.country.value ? data.country.value : '',
        tenantUrl: tenantUrl,
      })
    )
  }

  const onCancelClick = () => {
    window.location.href = 'login'
  }

  return (
    <div className="row p-0 m-0 d-flex justify-content-center">
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <div className="row p-0 m-0 mt-1">
          <div className="row p-0 mb-2 w-100 d-flex justify-content-between align-items-center">
            <div className="d-flex pl-3 align-items-center">
              <p className={cx(styles.createAccountText, 'm-0')}>{I18n.t('register.create_account')}</p>
            </div>
            <div className="d-flex align-items-center pr-3">
              <div className="d-flex pr-1" style={{ minWidth: '125px' }}>
                <LanguageChooser />
              </div>
              <p className={cx(styles.alreadyRegisteredText, 'borderLeft pl-2')}>
                {I18n.t('register.already_registered')}{' '}
                <Link
                  x-automation-id="sign-in-link"
                  id="sign-in-link"
                  to="/login"
                  onClick={onCancelClick}
                  className={styles.linkText}
                >
                  {I18n.t('register.sign_in')}
                </Link>
              </p>
            </div>
          </div>
          <div className="row p-0 mb-2 w-100">
            <div className="col-md-6">
              <Form.Group className={cx(styles.minHeightBox, 'm-0')}>
                <Form.Label className={cx(styles.inputLabel, styles.required)}>
                  {I18n.t('register.first_name')}
                </Form.Label>
                <Form.Control
                  className={styles.formInput}
                  type="text"
                  x-automation-id="first-name"
                  id="first-name"
                  {...register('firstName', { required: 'register.first_name_required' })}
                  placeholder={I18n.t('register.first_name')}
                  isInvalid={errors.firstName}
                />
                <Form.Control.Feedback type="invalid" className="m-0">
                  {I18n.t(errors?.firstName?.message || '')}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className="col-md-6">
              <Form.Group className={cx(styles.minHeightBox, 'm-0')}>
                <Form.Label className={cx(styles.inputLabel, styles.required)}>
                  {I18n.t('register.last_name')}
                </Form.Label>
                <Form.Control
                  className={styles.formInput}
                  type="text"
                  x-automation-id="last-name"
                  id="last-name"
                  {...register('lastName', { required: 'register.last_name_required' })}
                  placeholder={I18n.t('register.last_name')}
                  isInvalid={errors.lastName}
                />
                <Form.Control.Feedback type="invalid" className="m-0">
                  {I18n.t(errors?.lastName?.message || '')}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          </div>
          <div className="row p-0 mb-2 w-100">
            <div className="col-md-6">
              <Form.Group className={cx(styles.minHeightBox, 'm-0')}>
                <Form.Label className={cx(styles.inputLabel, styles.required)}>{I18n.t('register.email')}</Form.Label>
                <Form.Control
                  className={styles.formInput}
                  type="text"
                  x-automation-id="email"
                  id="email"
                  readOnly={!!inviteInfo}
                  {...register('email', {
                    required: 'register.email_required',
                    pattern: {
                      value: emailRegex,
                      message: 'register.email_required',
                    },
                  })}
                  placeholder={I18n.t('register.email')}
                  isInvalid={errors.email}
                />
                <Form.Control.Feedback type="invalid" className="m-0">
                  {I18n.t(errors?.email?.message || '')}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className="col-md-6">
              <Form.Group className={cx(styles.minHeightBox, 'm-0')}>
                <Form.Label className={cx(styles.inputLabel, styles.required)}>
                  {I18n.t('register.country')}
                </Form.Label>
                <Controller
                  name="country"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Select
                        placeholder={I18n.t('common.select')}
                        x-automation-id="ddlLocation"
                        id="ddlLocation"
                        value={locationsDDL}
                        classNamePrefix="react-select"
                        className={`p-0 ${formState.errors.country ? 'invalid-ddl' : ''}`}
                        {...field}
                        isClearable={false}
                        styles={{
                          control: p => ({
                            ...p,
                            border: formState.errors.country ? '1px solid red;' : '',
                          }),
                        }}
                        options={locationsDDL}
                      />
                    )
                  }}
                  rules={{
                    required: true,
                  }}
                />
                <ErrorMessage
                  className="invalid-feedback"
                  name="country"
                  as="div"
                  errors={formState.errors}
                  render={() => (
                    <p style={{ color: '#dc3545', fontSize: '80%' }}>{I18n.t('register.country_required')}</p>
                  )}
                />
              </Form.Group>
            </div>
          </div>
          <div className="row p-0 mb-2 w-100">
            <div className="col-md-6">
              <Form.Group className={cx(styles.minHeightBox, 'm-0')}>
                <Form.Label className={cx(styles.inputLabel, styles.required)}>{I18n.t('register.phone')}</Form.Label>
                <Form.Row className="pl-1">
                  <Controller
                    name="countryMobileCode"
                    control={control}
                    render={({ field }) => {
                      return (
                        <Select
                          placeholder={I18n.t('common.select')}
                          x-automation-id="ddlCountryLocationCode"
                          components={{ SingleValue }}
                          classNamePrefix="react-select"
                          className={`${styles.mobileCodeSelect} p-0 ${formState.errors.phone || formState?.errors?.countryMobileCode ? 'invalid-ddl' : ''
                            }`}
                          {...field}
                          isClearable={false}
                          styles={{
                            control: p => ({
                              ...p,
                              border: formState.errors.phone ? '1px solid red;' : '',
                            }),
                          }}
                          options={countryMobileCodeList}
                        />
                      )
                    }}
                  />
                  <Form.Control
                    className={cx(styles.formInput, styles.phoneNumberInput)}
                    type="text"
                    x-automation-id="phone"
                    id="phone"
                    {...register('phone', {
                      required: 'register.phone_required',
                      pattern: {
                        value: phoneNumber,
                        message: 'register.phoneNumberInvalid',
                      },
                    })}
                    placeholder={I18n.t('register.phone')}
                    isInvalid={errors.phone || errors?.countryMobileCode}
                  />
                  <Form.Control.Feedback type="invalid" className="m-0">
                    {errors.phone?.message
                      ? I18n.t(errors?.phone?.message || '')
                      : errors?.countryMobileCode && I18n.t('register.phone_required')}
                  </Form.Control.Feedback>
                </Form.Row>
              </Form.Group>
            </div>
            <div className="col-md-6">
              <Form.Group className={cx(styles.minHeightBox, 'm-0')}>
                <Form.Label className={cx(styles.inputLabel, styles.required)}>{I18n.t('register.state')}</Form.Label>
                <Controller
                  name="state"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Select
                        placeholder={I18n.t('common.select')}
                        x-automation-id="ddlState"
                        id="ddlState"
                        classNamePrefix="react-select"
                        className={`p-0 ${formState.errors.state ? 'invalid-ddl' : ''}`}
                        {...field}
                        isClearable
                        styles={{
                          control: p => ({
                            ...p,
                            border: formState.errors.state ? '1px solid red;' : '',
                          }),
                        }}
                        options={statesList}
                      />
                    )
                  }}
                  rules={{
                    required: true,
                  }}
                />
                <ErrorMessage
                  className="invalid-feedback"
                  name="state"
                  as="div"
                  errors={formState.errors}
                  render={() => (
                    <p style={{ color: '#dc3545', fontSize: '80%' }}>{I18n.t('register.state_required')}</p>
                  )}
                />
              </Form.Group>
            </div>
          </div>
          <div className="row p-0 mb-2 w-100">
            <div className="col-md-6">
              <Form.Group className={cx(styles.minHeightBox, 'm-0')}>
                <Form.Label className={cx(styles.inputLabel, styles.required)}>
                  {I18n.t('register.password')}
                </Form.Label>
                <Controller
                  name="password"
                  control={control}
                  rules={{
                    required: I18n.t('register.password_required'),
                    validate: {
                      valid: () =>
                        Object.values(validatePasswordInput(getValues('password')))?.every(e => e) ||
                        I18n.t('register.password_required'),
                    },
                  }}
                  render={({ field }) => {
                    return (
                      <PasswordInput
                        className={styles.formInput}
                        x-automation-id="password"
                        id="password"
                        {...field}
                        value={field?.value || ''}
                        placeholder={I18n.t('register.password')}
                        isInvalid={errors.password}
                        getValues={() => getValues('password')}
                      />
                    )
                  }}
                />
                <Form.Control.Feedback type="invalid" className="m-0">
                  {I18n.t(errors?.password?.message || '')}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className="col-md-6">
              <Form.Group className={cx(styles.minHeightBox, 'm-0')}>
                <Form.Label className={cx(styles.inputLabel, styles.required)}>
                  {I18n.t('register.confirm_password')}
                </Form.Label>
                <Form.Control
                  className={styles.formInput}
                  type="password"
                  x-automation-id="confirm-password"
                  id="confirm-password"
                  {...register('confirmPassword', {
                    required: 'register.confirm_password_required',
                    validate: {
                      positive: v => {
                        const p = getValues('password')
                        return p == v || 'register.confirm_password_not_match'
                      },
                    },
                  })}
                  placeholder={I18n.t('register.confirm_password')}
                  isInvalid={errors.confirmPassword}
                />
                <Form.Control.Feedback type="invalid" className="m-0">
                  {I18n.t(errors?.confirmPassword?.message || '')}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          </div>
          <div className="row p-0 mt-2 mb-2 w-100">

            <div className={cx(styles.columnAlignCenter, 'col-md-6')}>
              <div className="row w-100 m-0 p-0 d-flex align-items-center">
                <input
                  type="checkbox"
                  x-automation-id="terms-agree-checkbox"
                  id="terms-agree-checkbox"
                  className={styles.checkbox}
                  {...register('agree', {
                    required: I18n.t('register.terms_required'),
                  })}
                />
                <label className={cx(styles.alreadyRegisteredText, styles.termsConditionLabel, 'ml-2')}>
                  {I18n.t('register.agree')}{' '}
                  <a
                    x-automation-id="terms-agree-link"
                    id="terms-agree-link"
                    href={themeConfig?.tmsUse || 'https://www.cytrio.com/legal/TermsofService/'}
                    target="_blank"
                    className={styles.linkText}
                    rel="noreferrer"
                  >
                    {I18n.t('register.terms')}
                  </a>
                </label>
              </div>
              <div className="row w-100 m-0 p-0">
                {errors.agree?.message && (
                  <span className="invalid-feedback" style={{ display: 'block' }}>
                    {errors.agree?.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-6">
              {envVars?.ENABLE_PC_CAPTCHA === 'true' && (
                <input type="hidden" {...register('inviteCode', { required: 'register.captcha_required' })} />
              )}
              <ReCAPTCHA
                sitekey={envVars?.RECAPTCHAV2_SITEKEY}
                onChange={value => {
                  setValue('inviteCode', value)
                  clearErrors('inviteCode')
                }}
                onExpired={() => {
                  setValue('inviteCode', '')
                }}
              />
              <Form.Control.Feedback type="invalid" className="m-0 d-block">
                {I18n.t(errors?.inviteCode?.message || '')}
              </Form.Control.Feedback>
            </div>
          </div>
          <CommonFooter onCancelClick={onCancelClick} isRegisterForm={true} />
        </div>
      </Form>
    </div>
  )
}

export default RegisterForm
