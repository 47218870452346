import { Dispatch } from 'react'
import { Action } from 'redux'
import { DropdownModel } from '../../Models/index'
import service from './cities.service'
export const ACTION_GET_CITIES = 'ACTION_GET_CITIES'
export const ACTION_GET_CITIES_SUCCESS = 'ACTION_GET_CITIES_SUCCESS'
export const ACTION_GET_CITIES_FAILURE = 'ACTION_GET_CITIES_FAILURE'

export interface ActionGetCities extends Action {
  type: typeof ACTION_GET_CITIES
  payload: {}
}

export interface ActionGetCitiesSuccess extends Action {
  type: typeof ACTION_GET_CITIES_SUCCESS
  payload: {
    success: boolean
    data: Array<DropdownModel>
  }
}

export interface ActionGetCitiesFailure extends Action {
  type: typeof ACTION_GET_CITIES_FAILURE
  payload: {
    success: boolean
    errorMessage: string
  }
}

export type GetCitiesActions = ActionGetCities | ActionGetCitiesFailure | ActionGetCitiesSuccess

const dispatchGetCitiesSuccess = (success: boolean, data: Array<DropdownModel>): ActionGetCitiesSuccess => ({
  type: ACTION_GET_CITIES_SUCCESS,
  payload: {
    success,
    data,
  },
})

const dispatchGetCitiesFailure = (error: Error, success: boolean): ActionGetCitiesFailure => ({
  type: ACTION_GET_CITIES_FAILURE,
  payload: { errorMessage: error.message, success },
})

export function getCitiesDropdownAction(state: string, country: string, tenantUrl: string): (dispatch: Dispatch<GetCitiesActions>) => Promise<void> {
  return async (dispatch: Dispatch<GetCitiesActions>) => {
    try {
      const result = await service.getCitiesDropdown(state, country, tenantUrl)
      if (result && result.success) {
        let data: Array<DropdownModel> | undefined = result.data
        if (data) {
          dispatch(dispatchGetCitiesSuccess(true, data))
        } else {
          dispatch(dispatchGetCitiesFailure(new Error('Error while getting Cities.'), false))
        }
      } else {
        dispatch(dispatchGetCitiesFailure(new Error(result.message), false))
      }
    } catch (error) {
      dispatch(dispatchGetCitiesFailure(error, false))
    }
  }
}
