export const ACTION_START_LOADER = 'ACTION_START_LOADER'
export const ACTION_STOP_LOADER = 'ACTION_STOP_LOADER'

export const disptachStartLoading = (text: string) => ({
  type: ACTION_START_LOADER,
  payload: {
    text,
  },
})

export const disptachStopLoading = () => ({
  type: ACTION_STOP_LOADER,
})

export const loaderStart = (dispatch: any, isLoader: boolean = true, text: string = ''): void => {
  if (isLoader)
    dispatch(disptachStartLoading(text))
}

export const loaderStop = (dispatch: any, isLoader: boolean = true): void => {
  if (isLoader)
    dispatch(disptachStopLoading())
}

export interface LoaderReducerState {
  isLoading: boolean
  loadingText: string
}

export const initialState: LoaderReducerState = {
  isLoading: false,
  loadingText: '',
}

export const LoaderReducer = (
  state = initialState, //NOSONAR
  action: any
): LoaderReducerState => {
  switch (action.type) {
    case ACTION_START_LOADER:
      return {
        isLoading: true,
        loadingText: action.payload.text,
      }
    case ACTION_STOP_LOADER:
      return {
        isLoading: false,
        loadingText: '',
      }
    default:
      return state
  }
}
