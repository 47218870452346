import { ThemeConfig, RgbaColor } from '../Models/theme-config.model'
import { getEnvironmentVariables } from '../../../Config'
import { http } from '../../../Utils/AxiosWrapper'
import { tenant } from '../../Common/Constants/ApiUrls'
import { CommonApiResponse } from '../../Common/Models'

export interface ThemeConfigResponse extends CommonApiResponse {
  tenantUrl?: string
  themeConfig?: ThemeConfig
}

export interface CheckTenantResponse extends CommonApiResponse {
  tenantId?: number
  tenantName?: string
  domainId?: number
}

export interface ThemeConfigService {
  getThemeConfig(tenantUrl: string, tenantId: number, domainId: number): Promise<ThemeConfigResponse>
}

export class ThemeConfigServiceDefault implements ThemeConfigService {
  static buildUrl(tenantUrl: string): string {
    const envVars = getEnvironmentVariables()

    return `${envVars?.API_URL}/${tenantUrl}.json`
  }

  static parseRgba(color: string): RgbaColor {
    let rgba = parseInt(color, 10)
    if (!rgba) {
      rgba = 0
    }

    // eslint-disable-next-line no-bitwise
    const r = (rgba >> 24) & 0xff
    // eslint-disable-next-line no-bitwise
    const g = (rgba >> 16) & 0xff
    // eslint-disable-next-line no-bitwise
    const b = (rgba >> 8) & 0xff
    // eslint-disable-next-line no-bitwise
    const a = rgba & 0xff

    return new RgbaColor(r, g, b, a)
  }

  async checkTenant(tenantUrl: string): Promise<CheckTenantResponse> {
    let result: CheckTenantResponse
    try {
      const { data } = await http.get(tenant.checkTenant(tenantUrl))
      result = {
        success: true,
        tenantId: data.tenant_id,
        message: data.message,
        tenantName: data.tenant_name,
        domainId: data.tenant_domain_id,
      }
    } catch (error: any) {
      return {
        success: false,
        message: error.response.data.message,
      }
    }
    return result
  }

  async getThemeConfig(tenantUrl: string, tenantId: number, domainId: number): Promise<ThemeConfigResponse> {
    const envVars = getEnvironmentVariables()
    const url = envVars?.AZURE_PRIVACYCENTER_PUBLIC_URL + `/${tenantId}/${domainId}/config.json`
    let result: ThemeConfigResponse
    try {
      const { data } = await http.get(url)
      result = ThemeConfigServiceDefault.buildResponse(data, tenantUrl)
    } catch (error: any) {
      return {
        success: false,
        message: error.response.data.message,
      }
    }
    return result
  }

  static buildResponse(data: any, tenantUrl: string): ThemeConfigResponse {
    if (!data || !data?.desktop) {
      return {
        success: false,
        message: 'Empty response',
      }
    }

    const { desktop } = data

    const primaryColor = ThemeConfigServiceDefault.parseRgba(desktop.primary_color)
    const secondaryColor = ThemeConfigServiceDefault.parseRgba(desktop.secondary_color)
    const menuFont = ThemeConfigServiceDefault.parseRgba(desktop.menu_font)

    return {
      success: true,
      message: 'ok',
      tenantUrl: tenantUrl,
      themeConfig: {
        logo: desktop.logo,
        primaryColor,
        secondaryColor,
        fontColor: menuFont,
        menuStyleColor: desktop.menu_style_color,
        pcvPolicy: desktop.privacy_policy,
        tmsUse: desktop.terms_of_use,
        cntEmail: desktop.contact_email,
        textColor: desktop.banner_text_color,
        profileIconColor: desktop.profile_icon_color,
      },
    }
  }
}

export default new ThemeConfigServiceDefault()
