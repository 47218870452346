export const request = {
  open: 'Ouvert',
  close: 'Fermé',
  completed: 'Terminé',
  error: 'Erreur',
  inProgress: 'En cours',
  dsar: "Demandes d'accès aux données",
  iam: 'je suis un',
  customer: 'Client',
  employee: 'Employé',
  userTypeRequired: "Le type d'utilisateur est requis",
  chooseRequestType: 'Choisir le type de demande',
  requestTypeRequired: 'Le type de demande est requis',
  rightToAccess: "Droit d'accès",
  rightToAccessDescription:
    'Nous vous enverrons un rapport complet contenant les données que nous possédons à votre sujet',
  rightToDelete: 'Droit de suppression',
  rightToDeleteDescription:
    'Nous vous fournirons un rapport complet de vos données personnelles que nous avons supprimées de nos systèmes',
  rightToDoNotSell: 'Ne pas vendre',
  rightToDoNotSellDescription: 'Nous cesserons de partager/vendre vos informations personnelles à l\'un de nos partenaires publicitaires.',
  submitRequest: 'Envoyer la demande',
  submitRequestDescription: 'Fournissez les informations suivantes pour soumettre la demande',
  duplicateRequest: 'Une requête similaire est déjà en cours. Veuillez sélectionner un autre type de demande',
  requestSubmit: 'Demande soumise',
  noDataList: "Aucune demande d'accès aux données n'existe",
  noDataListSubHeadig: 'Soumettre la demande pour continuer',
  requestType: 'Type de demande',
  requestId: 'Identifiant de la demande',
  submitDate: 'Date de soumission',
  status: 'statut',
  completedDate: "Date d'achèvement",
  downeloadReport: 'Télécharger le rapport',
  downloadZip: 'Télécharger le zip',
  searchBy: 'Recherche par ID de demande',
  submitReqDescription:
    "Nous nous engageons à respecter vos droits à la vie privée et les données personnelles que vous partagez avec nous. Dans le cadre de cet engagement, le Centre de confidentialité vous confie la responsabilité de vos données et nous aide à répondre efficacement à vos demandes de confidentialité des données. Vous avez le droit d'obtenir une copie de vos données et le droit de supprimer vos données.",
}
