import React from 'react'
import { I18n } from 'react-redux-i18n'
import styles from '../Layout.module.css'
import { getCurrentYear } from '../../../Utils/dateAndTimeUtils'
import { useSelector } from 'react-redux'
import { AppStateType } from 'Store/reducers'

const Header = () => {
  const themeConfig = useSelector((state: AppStateType) => state.ConfigReducer?.themeConfig)
  return (
    <div className={styles.footer}>
      <div className={styles.footerContent}>
        <span>
          © {getCurrentYear()} {I18n.t('register.all_rights_reserved')}{' '}
        </span>
        <a href={themeConfig?.pcvPolicy || 'https://cytrio.com/legal/privacypolicy/'} target="_blank" className="pr-2">
          {I18n.t('register.privacy_policy')}
        </a>
        <span className={styles.vertical}></span>
        <a href={themeConfig?.tmsUse || 'https://www.cytrio.com/legal/TermsofService/'} target="_blank">
          {I18n.t('sidebar.terms_of_service')}
        </a>
      </div>
    </div>
  )
}

export default Header
