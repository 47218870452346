import { http } from '../../../Utils/AxiosWrapper'
import { auth } from '../../Common/Constants/ApiUrls'
import { SessionStorageKeyNames } from '../../Common/Enums'
import { SessionStorage } from '../../../Utils/SessionStorageWrapper'

export interface LoginUserRequest {
  email: string
  password: string
  tenantUrl: string
}

export interface LoginUserResponse {
  success: boolean
  message: string
  code: number
}

export interface LoginUserService {
  login(request: LoginUserRequest): Promise<LoginUserResponse>
}

class LoginUserServiceDefault implements LoginUserService {
  async login(request: LoginUserRequest): Promise<LoginUserResponse> {
    const bodyJSONData = {
      email: request.email,
      password: request.password,
    }
    let result: LoginUserResponse
    try {
      const { data } = await http.post(auth.login(request.tenantUrl), bodyJSONData)
      result = LoginUserServiceDefault.buildResponse(data)
    } catch (error) {
      result = {
        success: false,
        message: error.response.data.message,
        code: error.response.data.code,
      }
    }

    return result
  }

  static buildResponse(data: any): LoginUserResponse {
    if (!data) {
      return {
        success: false,
        message: 'Error no data in response',
        code: 4000,
      }
    }

    SessionStorage.setKey(SessionStorageKeyNames.TempToken, data.jwt_token.token)
    SessionStorage.setKey(SessionStorageKeyNames.TempTokenExpiry, data.jwt_token.exp_time_in_minutes)

    return {
      success: true,
      message: data.message,
      code: data.code,
    }
  }
}

export default new LoginUserServiceDefault()
