export const request = {
  open: 'Offen',
  close: 'Abgeschlossen',
  completed: 'Abgeschlossen',
  error: 'Fehler',
  inProgress: 'In Bearbeitung',
  dsar: 'Datenzugriffsanfragen',
  iam: 'Ich bin ein',
  customer: 'Kunde',
  employee: 'Mitarbeiter',
  userTypeRequired: 'Benutzertyp ist erforderlich',
  chooseRequestType: 'Art der Anfrage wählen',
  requestTypeRequired: 'Anfragetyp ist erforderlich',
  rightToAccess: 'Recht auf Zugriff',
  rightToAccessDescription: 'Wir senden Ihnen einen vollständigen Bericht mit Daten, die wir über Sie haben',
  rightToDelete: 'Recht auf Löschung',
  rightToDeleteDescription:
    'Wir stellen Ihnen einen vollständigen Bericht Ihrer personenbezogenen Daten zur Verfügung, die wir aus unseren Systemen gelöscht haben',
  rightToDoNotSell: 'Do Not Sell',
  rightToDoNotSellDescription: 'Wir werden Ihre personenbezogenen Daten nicht mehr an unsere Werbepartner weitergeben/verkaufen.',
  submitRequest: 'Anfrage einreichen',
  submitRequestDescription: 'Geben Sie die folgenden Informationen an, um die Anfrage zu senden',
  duplicateRequest: 'Eine ähnliche Anfrage ist bereits in Bearbeitung. Bitte wählen Sie einen anderen Anfragetyp',
  requestSubmit: 'Anfrage übermittelt',
  noDataList: 'Keine Datenzugriffsanforderung vorhanden',
  noDataListSubHeadig: 'Anfrage zum Fortfahren senden',
  requestType: 'Anfragetyp',
  requestId: 'Anfrage-ID',
  submitDate: 'Sendedatum',
  status: 'Status',
  completedDate: 'Fertigstellungsdatum',
  downeloadReport: 'Bericht herunterladen',
  downloadZip: 'Zip herunterladen',
  searchBy: 'Suche nach Anforderungs-ID',
  submitReqDescription:
    ' Wir verpflichten uns, Ihre Datenschutzrechte und die personenbezogenen Daten, die Sie uns mitteilen, zu respektieren. Im Rahmen dieser Verpflichtung überträgt Privacy Center Ihnen die Verantwortung für Ihre Daten und hilft uns, Ihre Datenschutzanfragen effizient zu erfüllen. Sie haben das Recht, eine Kopie Ihrer Daten zu erhalten und das Recht, Ihre Daten zu löschen.',
}
