import { Dispatch } from 'react'
import { Action } from 'redux'
import service from '../Service/request.service'
import { loaderStart, loaderStop } from '../../Loader/Reducers'
import { I18n } from 'react-redux-i18n'
import toastService from '../../Common/Services/Toaster'
import { ApiResponse, PageParams } from '../../Common/Models/index'
import { ResponseCodes } from '../../Common/Enums/Common'

export const ACTION_GET_REQUESTS_START = 'ACTION_GET_REQUESTS_START'
export const ACTION_GET_REQUESTS_SUCCESS = 'ACTION_GET_REQUESTS_SUCCESS'
export const ACTION_GET_REQUESTS_FAILURE = 'ACTION_GET_REQUESTS_FAILURE'

export interface ActionGetRequests extends Action {
  type: typeof ACTION_GET_REQUESTS_START
  payload: PageParams
}

export interface ActionGetRequestsSuccess extends Action {
  type: typeof ACTION_GET_REQUESTS_SUCCESS
  payload: ApiResponse<any>
}

export interface ActionGetRequestsFailure extends Action {
  type: typeof ACTION_GET_REQUESTS_FAILURE
  payload: {
    errorMessage: string
    isSuccess?: boolean
  }
}

export type GetRequestsActions = ActionGetRequests | ActionGetRequestsFailure | ActionGetRequestsSuccess

export const dispatchGetRequests = (pageParams: PageParams): ActionGetRequests => ({
  type: ACTION_GET_REQUESTS_START,
  payload: pageParams,
})

const dispatchGetRequestsSuccess = (data: ApiResponse<any>): ActionGetRequestsSuccess => ({
  type: ACTION_GET_REQUESTS_SUCCESS,
  payload: data,
})

const dispatchGetRequestsFailure = (error: Error, isSuccess: boolean = false): ActionGetRequestsFailure => ({
  type: ACTION_GET_REQUESTS_FAILURE,
  payload: { errorMessage: error.message, isSuccess },
})

export function actionGetRequests(
  req: PageParams,
  tenantUrl: string,
  isLoader: boolean = true
): (dispatch: Dispatch<GetRequestsActions>) => Promise<void> {
  return async (dispatch: Dispatch<GetRequestsActions>) => {
    loaderStart(dispatch, isLoader)
    try {
      const result = await service.getRequests(req, tenantUrl)
      if (result && result.success) {
        dispatch(dispatchGetRequestsSuccess(result))
      } else {
        if (result.statusCode == ResponseCodes.NotFound) {
          dispatch(
            dispatchGetRequestsFailure(new Error(result?.message || I18n.t('common.toast.something_went_wrong')), true)
          )
        } else {
          if (result.statusCode !== ResponseCodes.NotFound && result.statusCode !== ResponseCodes.Unauthorized) {
            toastService.error(I18n.t('common.toast.something_went_wrong'))
          }
          dispatch(
            dispatchGetRequestsFailure(new Error(result?.message || I18n.t('common.toast.something_went_wrong')))
          )
        }
      }
      loaderStop(dispatch, isLoader)
    } catch (error) {
      toastService.error(I18n.t('common.toast.something_went_wrong'))
      dispatch(dispatchGetRequestsFailure(error))
      loaderStop(dispatch)
    }
  }
}
