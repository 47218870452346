import cx from 'classnames'
import React from 'react'
import { AlertTriangle, ArrowRight } from 'react-feather'
import styles from '../Auth.module.css'
import ButtonWithIcon from 'Modules/Common/Components/Button'
import { useHistory } from 'react-router'
import { I18n } from 'react-redux-i18n'
import ModalPopup from "../../Common/Components/Model";

interface ResetLinkExpiredPopupProps {
  isShowPopup: boolean
  setIsShowPopup: (isShow: boolean) => void
}

function ResetLinkExpiredPopup(props: ResetLinkExpiredPopupProps) {
  const { isShowPopup, setIsShowPopup } = props
  const history = useHistory()
  let bodyChild: JSX.Element = (
    <div className="row mr-2 ml-2">
      <div className="col-md-12 d-flex justify-content-center">
        <AlertTriangle height={40} width={40} color="#E90000" />
      </div>
      <div className="col-md-12 mt-2 text-center">
        <strong className={styles.sentPopupHeading}>{I18n.t('forgetPass.linkExpired')}</strong>
      </div>
      <div className={cx('col-md-12 mt-2 text-center', styles.sentPopupDescription)}>
        {I18n.t('forgetPass.linkExpiredContent')}
      </div>
      <div className={cx('col-md-12 mt-4 d-flex justify-content-center')}>
        <ButtonWithIcon
          type="button"
          text={I18n.t('forgetPass.resendLink')}
          style={{ width: '50%' }}
          icon={<ArrowRight color="#FFFFFF" />}
          iconToRight={true}
          x-automation-id="resend-btn"
          id="next-btn"
          className={styles.customButton}
          onClick={() => {
            history.push('/forgotpassword')
            setIsShowPopup(false)
          }}
        />
      </div>
    </div>
  )
  return (
    <>
      <ModalPopup
        show={isShowPopup}
        dialogClassName="sentModal"
        x-automation-id="add-datasource-popup"
        bodyChild={bodyChild}
        onHide={() => setIsShowPopup(false)}
      />
    </>
  )
}

export default React.memo(ResetLinkExpiredPopup)
