import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'
import { ArrowRight } from 'react-feather'
import styles from '../Auth.module.css'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { AppStateType } from '../../../Store/reducers'
import ButtonWithIcon, { CancelButtonWithIcon } from '../../Common/Components/Button'
import ErrorBoundary from '../../Common/Components/ErrorBoundary'
import { actionForgotPasswordInfo, dispatchForgotPassword } from '../Actions/forget-password.actions'
import SentPopup from './SentPopup'
import { LanguageChooserColMD, LoginFormEmailRow } from '../CommonCode'

const ForgotPassword = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm()
  const { isAuthenticated } = useSelector((state: AppStateType) => state.AuthReducer)

  const forgotPassword = useSelector((state: AppStateType) => state.AuthReducer.forgotPassword)
  const { tenantUrl } = useSelector((state: AppStateType) => state.ConfigReducer)
  const { locale } = useSelector((state: AppStateType) => state.i18n)

  const history = useHistory()

  const dispatch = useDispatch()

  const [isShowPopup, setIsShowPopup] = useState(false)

  useEffect(() => {
    if (forgotPassword?.success) {
      setIsShowPopup(true)
      dispatch(dispatchForgotPassword())
    }
  }, [forgotPassword])
  if (isAuthenticated) {
    history.push('/')
  }
  const onSubmit = (data: any) => {
    dispatch(actionForgotPasswordInfo(data.email, tenantUrl))
  }

  const onBackToLogin = () => {
    history.push('/login')
  }
  const onPopupClose = () => {
    setIsShowPopup(false)
    setValue('email', '')
  }

  return (
    <ErrorBoundary>
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <div className="row p-0 m-0 m-5">
          <LanguageChooserColMD
            label={I18n.t('forgetPass.forgetPass')}
            subLabel={I18n.t('forgetPass.forgetPassDescription')}
          />
          <LoginFormEmailRow register={register} errors={errors} />
          <div className="col-md-6 d-flex justify-content-end pr-1 mt-3">
            <CancelButtonWithIcon
              type="button"
              text={I18n.t('forgetPass.backToLogin')}
              style={{ width: '100%' }}
              x-automation-id="back-btn"
              id="back-btn"
              onClick={onBackToLogin}
              className={styles.customButton}
            />
          </div>
          <div className="col-md-6 pl-1 mt-3">
            <ButtonWithIcon
              type="submit"
              text={I18n.t('common.send')}
              style={{ width: '100%' }}
              icon={<ArrowRight color="#FFFFFF" />}
              iconToRight={true}
              x-automation-id="next-btn"
              id="next-btn"
              className={styles.customButton}
            />
          </div>
        </div>
        <SentPopup isShowPopup={isShowPopup} setIsShowPopup={onPopupClose} />
      </Form>
    </ErrorBoundary>
  )
}

export default React.memo(ForgotPassword)
