import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Login from '../Auth/Login'
import Register from '../Auth/Register'
import Layout from '../Layout'
import { Pages } from '../Common/Enums/Common'
import { AppStateType } from 'Store/reducers'
import { useSelector } from 'react-redux'

interface Props {
  tenantUrl: string
}

const Home = ({ tenantUrl }: Props) => {
  const { locale } = useSelector((state: AppStateType) => state.i18n)
  if (!locale) {
    return null
  }
  return (
    <div className="App" x-automation-id="homeApp">
      <BrowserRouter basename={tenantUrl}>
        <Switch>
          <Route path="/login" exact>
            <Login page={Pages.LoginPage} />
          </Route>
          <Route path="/login/sendCode">
            <Login page={Pages.SendCodePage} />
          </Route>
          <Route path="/login/verifyCode">
            <Login page={Pages.VerifyCodePage} />
          </Route>
          <Route path="/forgotpassword">
            <Login page={Pages.ForgotPassword} />
          </Route>
          <Route path="/resetpassword">
            <Login page={Pages.ResetPassword} />
          </Route>
          <Route path="/register" exact>
            <Register tenantUrl={tenantUrl} page={Pages.RegisterPage} />
          </Route>
          <Route path="/register/verifyCode">
            <Register tenantUrl={tenantUrl} page={Pages.VerifyCodePage} />
          </Route>
          <Route path="/">
            <Layout />
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  )
}

export default Home
