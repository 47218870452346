import { Dispatch } from 'react'
import { Action } from 'redux'
import service, { LoginUserRequest } from '../Services/login.service'
import { loaderStart, loaderStop } from '../../Loader/Reducers'
import { I18n } from 'react-redux-i18n'
import toastService from '../../Common/Services/Toaster'

export const ACTION_LOGIN = 'ACTION_LOGIN'
export const ACTION_LOGIN_CANCEL = 'ACTION_LOGIN_CANCEL'
export const ACTION_LOGIN_SUCCESS = 'ACTION_LOGIN_SUCCESS'
export const ACTION_LOGIN_FAILED = 'ACTION_LOGIN_FAILED'
export const ACTION_PARTIAL_LOGIN = 'ACTION_PARTIAL_LOGIN'

export interface ActionPartialLogin extends Action {
  type: typeof ACTION_PARTIAL_LOGIN
}
export interface ActionLogin extends Action {
  type: typeof ACTION_LOGIN
}

export interface ActionLoginCancel extends Action {
  type: typeof ACTION_LOGIN_CANCEL,
  payload: {
    redirectToRegister: boolean
  }
}

export interface ActionLoginSuccess extends Action {
  type: typeof ACTION_LOGIN_SUCCESS
  payload: {
    email: string
  }
}

export interface ActionLoginFailed extends Action {
  type: typeof ACTION_LOGIN_FAILED
  payload: {
    errorMessage: string
  }
}

export type LoginActions = ActionLogin | ActionLoginFailed | ActionLoginSuccess | ActionLoginCancel | ActionPartialLogin

const dispatchPartialLogin = (): ActionPartialLogin => ({
  type: ACTION_PARTIAL_LOGIN,
})

export const dispatchLogin = (): ActionLogin => ({
  type: ACTION_LOGIN,
})

const dispatchLoginSuccess = (email: string): ActionLoginSuccess => ({
  type: ACTION_LOGIN_SUCCESS,
  payload: {
    email,
  },
})

const dispatchLoginFailed = (error: Error): ActionLoginFailed => ({
  type: ACTION_LOGIN_FAILED,
  payload: { errorMessage: error.message },
})

export const dispatchLoginCancel = (redirectToRegister: boolean): ActionLoginCancel => ({
  type: ACTION_LOGIN_CANCEL,
  payload: { redirectToRegister }
})

const toaster = (code: number) => {
  switch (code) {
    case 4001:
      toastService.error(I18n.t('auth_toast.invalid_login'))
      break
    case 4011:
      toastService.error(I18n.t('auth_toast.sign_up_pending'))
      break
    default:
      toastService.error(I18n.t('common.toast.something_went_wrong'))
      break
  }
}

export function actionLogin(req: LoginUserRequest): (dispatch: Dispatch<LoginActions>) => Promise<void> {
  return async (dispatch: Dispatch<LoginActions>) => {
    dispatch(dispatchLogin())
    loaderStart(dispatch)
    try {
      const result = await service.login(req)
      if (result && result.success) {
        dispatch(dispatchLoginSuccess(req.email))
      } else if (result && result.code === 4011) {
        toaster(result?.code)
        dispatch(dispatchPartialLogin())
      } else {
        toaster(result?.code)
        dispatch(dispatchLoginFailed(new Error(result?.message || 'Something went wrong.')))
      }
      loaderStop(dispatch)
    } catch (error: any) {
      dispatch(dispatchLoginFailed(error))
      loaderStop(dispatch)
    }
  }
}
