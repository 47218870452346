import { http } from '../../../Utils/AxiosWrapper'
import { auth } from '../../Common/Constants/ApiUrls'

export interface LogoutUserResponse {
  success: boolean
  message: string
  code: number
}

export interface LogoutUserService {
  logout(tenantUrl: string): Promise<LogoutUserResponse>
}

class LogoutUserServiceDefault implements LogoutUserService {
  async logout(tenantUrl: string): Promise<LogoutUserResponse> {
    
    let result: any
    try {
      const { data } = await http.get(auth.logout(tenantUrl))
      result = {
        success: true,
        message: data.message,
        code: data.code
      }
    } catch (error) {
      result = {
        success: false,
        message: error.response.data.message,
        code: error.response.data.code,
      }
    }

    return result
  }

}

export default new LogoutUserServiceDefault()
