import { Dispatch } from 'react'
import { Action } from 'redux'
import { loaderStart, loaderStop } from '../../Loader/Reducers'
import service from '../Services/register.service'
import { I18n } from 'react-redux-i18n'
import toastService from '../../Common/Services/Toaster'
import { ResponseCodes } from "../../../Modules/Common/Enums/Common";

export const ACTION_REGISTER = 'ACTION_REGISTER'
export const ACTION_REGISTER_SUCCESS = 'ACTION_REGISTER_SUCCESS'
export const ACTION_REGISTER_FAILED = 'ACTION_REGISTER_FAILED'

export interface ActionRegister extends Action {
  type: typeof ACTION_REGISTER,
  payload: {
    data: any
  }
}

export interface ActionRegisterSuccess extends Action {
  type: typeof ACTION_REGISTER_SUCCESS
  payload: {
    email: string
  }
}

export interface ActionRegisterFailed extends Action {
  type: typeof ACTION_REGISTER_FAILED
  payload: {
    errorMessage: string
  }
}

export type RegisterActions = ActionRegister | ActionRegisterFailed | ActionRegisterSuccess

const dispatchRegister = (data): ActionRegister => ({
  type: ACTION_REGISTER,
  payload: { data }
})

const dispatchRegisterSuccess = (email: string): ActionRegisterSuccess => ({
  type: ACTION_REGISTER_SUCCESS,
  payload: {
    email,
  },
})

const dispatchRegisterFailed = (error: Error): ActionRegisterFailed => ({
  type: ACTION_REGISTER_FAILED,
  payload: { errorMessage: error.message },
})

const toaster = (code: number) => {
  switch (code) {
    case ResponseCodes.NoUserFound:
      toastService.error(I18n.t('auth_toast.invalid_invite'))
      break
    case ResponseCodes.Conflicts:
      toastService.error(I18n.t('auth_toast.user_already_exists'))
      break
    case ResponseCodes.Expired:
      toastService.error(I18n.t('auth_toast.captch_expited'))
      break
    default:
      toastService.error(I18n.t('common.toast.something_went_wrong'))
      break
  }
}

export function actionRegister(data: any): (dispatch: Dispatch<RegisterActions>) => Promise<void> {
  return async (dispatch: Dispatch<RegisterActions>) => {
    dispatch(dispatchRegister(data))
    loaderStart(dispatch)
    try {
      const result = await service.register(data)
      if (result && result.success) {
        dispatch(dispatchRegisterSuccess(data.email))
      } else {
        toaster(result?.code)
        dispatchRegisterFailed(new Error(result?.message || 'Something went wrong.'))
      }
      loaderStop(dispatch)
    } catch (error) {
      dispatch(dispatchRegisterFailed(error))
      loaderStop(dispatch)
    }
  }
}
