import react, { useEffect, useState } from 'react'
import Select, { components, OptionProps, SingleValueProps } from 'react-select'
import { LanguageDropdownModel } from '../../Common/Models'
import * as Icons from '../../../Assets/Images/Icons/Flags'
import { dispatchSetLocale } from 'l10n/Actions/i18n'
import { useDispatch } from 'react-redux'
import { SessionStorage } from 'Utils/SessionStorageWrapper'
import { SessionStorageKeyNames } from '../Enums'

export const SingleValue = ({ children, ...props }: SingleValueProps<LanguageDropdownModel>) => {
  return (
    <components.SingleValue {...props}>
      {Icons && Icons[props?.data?.icon] && (
        <img
          style={{
            width: '18px',
            height: '18px',
            marginRight: '10px',
          }}
          src={Icons[props?.data?.icon]}
        />
      )}
      {children}
    </components.SingleValue>
  )
}

export const Option = ({ children, ...props }: OptionProps<LanguageDropdownModel, false>) => {
  return (
    <components.Option {...props}>
      {Icons && Icons[props?.data?.icon] && (
        <img
          style={{
            width: '18px',
            height: '18px',
            marginRight: '10px',
          }}
          src={Icons[props?.data?.icon]}
        />
      )}
      {children}
    </components.Option>
  )
}

const languageOptions: Array<LanguageDropdownModel> = [
  {
    label: 'English',
    value: 'en',
    icon: 'US',
  },
  {
    label: 'German',
    value: 'de',
    icon: 'DE',
  },
  {
    label: 'Spanish',
    value: 'es',
    icon: 'ES',
  },
  {
    label: 'French',
    value: 'fr',
    icon: 'FR',
  },
]

type LanguageChooserProps = {
  singleValueColor?: string
}

const LanguageChooser = ({ singleValueColor }: LanguageChooserProps) => {
  const [selectedLanguage, setSelectedLanguage] = useState<LanguageDropdownModel>(languageOptions[0])
  const dispatch = useDispatch()

  const onLanguageChange = (language: LanguageDropdownModel) => {
    setSelectedLanguage(language)
    SessionStorage.setKey(SessionStorageKeyNames.Language, language?.value)
    dispatch(dispatchSetLocale(language?.value))
  }

  useEffect(() => {
    const lang = SessionStorage.get(SessionStorageKeyNames.Language) || 'en'
    const foundLang = languageOptions?.find(e => e.value === lang)
    if (foundLang) {
      setSelectedLanguage(foundLang)
    }
  }, [])

  return (
    <Select
      components={{ SingleValue, Option }}
      x-automation-id="ddlLocation"
      classNamePrefix="react-select-language"
      className="p-0 form-control"
      isClearable={false}
      isSearchable={false}
      value={selectedLanguage}
      options={languageOptions}
      onChange={onLanguageChange}
      styles={{
        option: base => ({
          ...base,
          cursor: 'pointer',
        }),
        indicatorSeparator: base => ({
          ...base,
          width: '0px',
        }),
        control: base => ({
          ...base,
          backgroundColor: 'transparent',
          border: 'none',
          boxShadow: 'none',
          cursor: 'pointer',
        }),
        singleValue: base => ({
          ...base,
          color: singleValueColor ? singleValueColor : 'rgba(0, 0, 0, 0.8)',
        }),
        dropdownIndicator: base => ({
          ...base,
          color: singleValueColor ? singleValueColor : 'rgba(0, 0, 0, 0.8)',
          '&:hover': {
            color: singleValueColor ? singleValueColor : 'rgba(0, 0, 0, 0.8)',
          },
        }),
        container: base => ({
          ...base,
          backgroundColor: 'transparent',
          border: 'none',
        }),
      }}
    />
  )
}

export default LanguageChooser
