import { PageParams } from "../Modules/Common/Models";

const convertToString = (value: string | string[]): string => {
  if (value instanceof Array) {
    return value.join('');
  }
  return value;
};

export default convertToString;

export const buildParams = (p: PageParams) => {
  const params: any = {}
  params.page_no = p.page_no
  params.page_size = p.page_size
  if (p.search && p.search != '') {
    params.search = p.search
  }
  if (p.sort_column && p.sort_column != '') {
    params.sort_column = p.sort_column
  }
  if (p.sort_direction && p.sort_direction != '') {
    params.sort_direction = p.sort_direction
  }
  return params
}
