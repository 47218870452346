import LanguageChooser from 'Modules/Common/Components/LanguageChooser'
import { emailRegex } from 'Modules/Common/Constants'
import { Form } from 'react-bootstrap'
import { Mail } from 'react-feather'
import { I18n } from 'react-redux-i18n'
import styles from './Auth.module.css'
import cx from 'classnames'

export const LanguageChooserColMD = ({ label, subLabel }) => {
  return (
    <>
      <div className="col-md-12 d-flex justify-content-between">
        <p className={styles.createAccountText}>{label}</p>
        <div className="d-flex pr-1" style={{ minWidth: '125px' }}>
          <LanguageChooser />
        </div>
      </div>
      <div className="col-md-12">
        <p className={styles.formDescription}>{subLabel}</p>
      </div>
    </>
  )
}

export const LoginFormEmailRow = ({ register, errors }) => {
  return (
    <div className="row p-0 m-0 w-100">
      <div className="col-md-12">
        <Form.Group className={cx(styles.minHeightBox, 'm-0')}>
          <Form.Label className={cx(styles.inputLabel, styles.required)}>{I18n.t('login.email')}</Form.Label>
          <Form.Control
            className={cx(styles.formInput, styles.loginFormInput)}
            x-automation-id="email"
            id="email"
            type="text"
            {...register('email', {
              required: 'register.email_required',
              pattern: {
                value: emailRegex,
                message: 'register.email_required',
              },
            })}
            placeholder={I18n.t('login.email')}
            isInvalid={errors.email}
          />
          <span className={styles.loginFormInputIcon}>
            <Mail color="#000000" size={20} />
          </span>
          <Form.Control.Feedback type="invalid" className="m-0">
            {I18n.t(errors?.email?.message || '')}
          </Form.Control.Feedback>
        </Form.Group>
      </div>
    </div>
  )
}
