import { Dispatch } from 'react'
import { Action } from 'redux'
import { InviteUserInfoModel } from '../Models/user.model'
import { loaderStart, loaderStop } from '../../Loader/Reducers'
import service from '../Services/invite-info.service'
import { I18n } from 'react-redux-i18n'
import toastService from '../../Common/Services/Toaster'
import { ResponseCodes } from 'Modules/Common/Enums/Common'

export const ACTION_GET_INVITE_INFO = 'ACTION_GET_INVITE_INFO'
export const ACTION_GET_INVITE_INFO_SUCCESS = 'ACTION_GET_INVITE_INFO_SUCCESS'
export const ACTION_GET_INVITE_INFO_FAILED = 'ACTION_GET_INVITE_INFO_FAILED'

export interface ActionGetInviteInfo extends Action {
  type: typeof ACTION_GET_INVITE_INFO
}

export interface ActionGetInviteInfoSuccess extends Action {
  type: typeof ACTION_GET_INVITE_INFO_SUCCESS
  payload: {
    inviteInfo: InviteUserInfoModel
  }
}

export interface ActionGetInviteInfoFailed extends Action {
  type: typeof ACTION_GET_INVITE_INFO_FAILED
  payload: {
    errorMessage: string
  }
}

export type GetInviteInfoActions = ActionGetInviteInfo | ActionGetInviteInfoFailed | ActionGetInviteInfoSuccess

const dispatchGetInviteInfo = (): ActionGetInviteInfo => ({
  type: ACTION_GET_INVITE_INFO,
})

const dispatchGetInviteInfoSuccess = (inviteInfo: any): ActionGetInviteInfoSuccess => ({
  type: ACTION_GET_INVITE_INFO_SUCCESS,
  payload: {
    inviteInfo,
  },
})

const dispatchGetInviteInfoFailed = (error: Error): ActionGetInviteInfoFailed => ({
  type: ACTION_GET_INVITE_INFO_FAILED,
  payload: { errorMessage: error.message },
})

export function actionGetInviteInfo(
  data: any,
  history: any,
  tenantUrl: string
): (dispatch: Dispatch<GetInviteInfoActions>) => Promise<void> {
  return async (dispatch: Dispatch<GetInviteInfoActions>) => {
    dispatch(dispatchGetInviteInfo())
    loaderStart(dispatch)
    try {
      const result = await service.getInviteInfo(data, tenantUrl)
      if (result && result.success) {
        dispatch(dispatchGetInviteInfoSuccess(result.inviteInfo))
      } else {
        if (result?.code === ResponseCodes.NotFound) {
          toastService.error(I18n.t('auth_toast.link_expired'))
          history.push('/login')
        } else {
          toastService.error(I18n.t('common.toast.something_went_wrong'))
        }
        dispatch(dispatchGetInviteInfoFailed(new Error(result?.msg || 'Something went wrong.')))
      }
      loaderStop(dispatch)
    } catch (error) {
      dispatch(dispatchGetInviteInfoFailed(error))
      loaderStop(dispatch)
    }
  }
}
