import {
  UserConfigActions,
  ACTION_CONFIG_CHANGE,
  ACTION_CONFIG_CHANGE_SUCCESS,
  ACTION_CONFIG_CHANGE_FAILURE,
} from '../Actions/config.actions'
import { ThemeConfig } from '../Models/theme-config.model'

export interface ConfigState {
  tenantNotFound: boolean
  tenantUrl: string
  errorMessage?: string
  tenantId?: string
  tenantName?: string
  themeConfig?: ThemeConfig
}

export const initialState: ConfigState = {
  tenantNotFound: false,
  tenantUrl: '',
}

export const ConfigReducer = (
  state = initialState, //NOSONAR
  action: UserConfigActions
): ConfigState => {
  switch (action.type) {
    case ACTION_CONFIG_CHANGE:
      return {
        ...state,
        errorMessage: undefined,
      }
    case ACTION_CONFIG_CHANGE_SUCCESS:
      return {
        ...state,
        tenantUrl: action.payload.tenantUrl,
        tenantName: action.payload.tenantName,
        themeConfig: action.payload.config,
        errorMessage: undefined,
        tenantNotFound: false,
      }
    case ACTION_CONFIG_CHANGE_FAILURE:
      return {
        ...state,
        errorMessage: action.payload.errorMessage,
        tenantNotFound: true,
      }
    default:
      return state
  }
}
