import cx from 'classnames'
import React from 'react'
import ModalPopup from '../../Common/Components/Model'
import styles from '../Auth.module.css'
import { I18n } from 'react-redux-i18n'
import ButtonWithIcon from '../../Common/Components/Button'
import { bodyChildContent } from '../Common'

interface SentPopupProps {
  isShowPopup: boolean
  setIsShowPopup: (isShow: boolean) => void
}

function SentPopup(props: SentPopupProps) {
  const { isShowPopup, setIsShowPopup } = props
  let bodyChild: JSX.Element = (
    <div className="row mr-2 ml-2">
      {bodyChildContent(false)}
      <div className={cx('col-md-12 mt-2 text-center', styles.sentPopupDescription)}>
        {I18n.t('forgetPass.passwordResetSubContent')}
      </div>
      <div className={cx('col-md-12 mt-2')}>
        <ButtonWithIcon
          type="button"
          text={I18n.t('common.ok')}
          style={{ width: '100%' }}
          x-automation-id="ok-btn-sent-model"
          id="ok-btn"
          className={styles.customButton}
          onClick={() => {
            setIsShowPopup(false)
          }}
        />
      </div>
    </div>
  )
  return (
    <>
      <ModalPopup
        show={isShowPopup}
        dialogClassName="sentModal"
        x-automation-id="send-model-popup"
        bodyChild={bodyChild}
        onHide={() => setIsShowPopup(false)}
      />
    </>
  )
}

export default React.memo(SentPopup)
