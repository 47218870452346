import axios from 'axios'

interface EnvObjectData {
  API_URL: string
  PRIVACY_CENTER_URL: string
  APP_BASEROUTE: string
  RECAPTCHAV2_SITEKEY: string
  AZURE_DEFAULT_LOGO_PATH: string
  AZURE_PRIVACYCENTER_PUBLIC_URL: string
  ENABLE_PC_CAPTCHA: string
  REFRESH_INTERVAL: string
  SUPPORT_EMAIL: string
  WALKTHROUGH_ID: string
}

let _envVars: EnvObjectData

export function loadEnvironmentVariables() {
  return new Promise(resolve => {
    axios
      .get('/configpc.json')
      .then((data: any) => {
        _envVars = data?.data
        return resolve(_envVars)
      })
      .catch((err: any) => {
        console.log('Error while loading env')
      })
  })
}

loadEnvironmentVariables()
  .then((data: any) => {
    _envVars = data
  })
  .catch((err: any) => {
    console.error('Error while loading Env Config')
  })

export function getEnvironmentVariables() {
  if (_envVars) {
    return _envVars
  } else {
    loadEnvironmentVariables().then((data: any) => {
      _envVars = data
      return _envVars
    })
  }
}
