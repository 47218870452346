import React from 'react'
import { Modal } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'

interface VerticallyCenteredModalProps {
  footerChild?: JSX.Element
  heading?: string
  bodyChild: JSX.Element
  show: boolean
  onHide: () => void
  dialogClassName?: string
  tenantName?: string
}

const Model = (props: VerticallyCenteredModalProps) => {
  const { show, onHide, footerChild, heading, tenantName, bodyChild, dialogClassName } = props
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName={dialogClassName}
      backdrop="static"
    >
      <Modal.Header style={{ borderBottom: 'none', paddingBottom: 0 }}>
        <div className="col-md-12 d-flex flex-column">
          <Modal.Title id="contained-modal-title-center">{heading}</Modal.Title>
          {tenantName && (
            <p className="model-subHeading pt-2">
              <span>{tenantName}</span> {I18n.t('request.submitReqDescription')}
            </p>
          )}
        </div>
      </Modal.Header>
      <Modal.Body style={{ paddingTop: 0 }}>{bodyChild}</Modal.Body>
      {footerChild && <Modal.Footer className="model-footer">{footerChild}</Modal.Footer>}
    </Modal>
  )
}

export default Model
