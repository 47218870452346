import { http } from '../../../../Utils/AxiosWrapper/index'
import { ApiResponse, LocationDropdownModel } from '../../Models'
import { common } from '../../../Common/Constants/ApiUrls'

interface LocationService {
  getlocationDropdown(tenantUrl: string): Promise<ApiResponse<Array<LocationDropdownModel>>>
}

class LocationServiceDefault implements LocationService {
  async getlocationDropdown(tenantUrl: string): Promise<ApiResponse<Array<LocationDropdownModel>>> {
    let response: ApiResponse<Array<LocationDropdownModel>> = {
      data: [],
      message: '',
      success: false,
    }
    try {
      const apiData = await http.get(common.location(tenantUrl))
      if (apiData && apiData.data && apiData.data.locations && apiData.data.locations.length > 0) {
        apiData.data.locations.forEach((loc: any) => {
          let location: LocationDropdownModel = {
            value: loc.iso3,
            label: loc.country_name,
            iso: loc.iso,
            iso3: loc.iso3,
            countryMobileCode: '+' + loc.country_mobile_code,
            states:
              loc?.states?.length > 0
                ? loc.states.map(stateItem => {
                    return {
                      value: stateItem?.code,
                      label: stateItem?.state_name,
                      country: loc.country_name,
                      countryISO: loc?.iso3,
                    }
                  })
                : [],
          }
          response.data?.push(location)
        })
        response.success = true
        response.message = 'Success'
      } else {
        response = {
          success: false,
          message: '',
          data: [],
        }
      }
    } catch (error) {
      response = {
        success: false,
        message: '',
        data: [],
      }
    }
    return response
  }
}

export default new LocationServiceDefault()
