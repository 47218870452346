import { Form } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'
import { ArrowRight, Lock } from 'react-feather'
import ButtonWithIcon from '../../Common/Components/Button'
import styles from '../Auth.module.css'
import cx from 'classnames'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import { AppStateType } from '../../../Store/reducers'
import { actionLogin } from '../Actions/login.actions'
import { loadEnvironmentVariables } from 'Config'
import { useState, useEffect } from 'react'
import LanguageChooser from '../../Common/Components/LanguageChooser'
import { LoginFormEmailRow } from '../CommonCode'

const LoginForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const dispatch = useDispatch()

  const [envVar, setEnvVar] = useState<any>()

  useEffect(() => {
    loadEnvironmentVariables().then(data => {
      setEnvVar(data)
    })
  }, [])

  const { isAuthenticated } = useSelector((state: AppStateType) => state.AuthReducer)
  const { tenantUrl } = useSelector((state: AppStateType) => state.ConfigReducer)
  const themeConfig = useSelector((state: AppStateType) => state.ConfigReducer.themeConfig)

  if (isAuthenticated) {
    return <Redirect to="/login/verifyCode" />
  }

  const onSubmit = (data: any) => {
    dispatch(actionLogin({ ...data, tenantUrl }))
  }

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
      <div className="row p-0 m-0 m-5">
        <div className="col-md-12 d-flex justify-content-between">
          <div className={cx(styles.createAccountText, 'w-100')}>{I18n.t('login.sign_in')}</div>
          <div className="d-flex" style={{ minWidth: '120px' }}>
            <LanguageChooser />
          </div>
        </div>
        <div className="col-md-12 d-flex pt-3 pb-3">
          <div className={cx(styles.alreadyRegisteredText, 'pr-2')}>{I18n.t('login.dont_have_account')}</div>
          <div>
            <Link id="sign-up-link" className={styles.linkTextSignup} to="/register">
              {' '}
              {I18n.t('login.sign_up')}
            </Link>
          </div>
        </div>
        <div className="col-md-12 d-flex justify-content-between pt-2">
          <p className={cx(styles.signinCred, 'mb-2')}>{I18n.t('login.sign_in_cred')}</p>
        </div>
        <LoginFormEmailRow register={register} errors={errors} />
        <div className="row p-0 m-0 w-100 mt-2">
          <div className="col-md-12">
            <Form.Group className={cx(styles.minHeightBox, 'm-0')}>
              <Form.Label className={cx(styles.inputLabel, styles.required)}>{I18n.t('register.password')}</Form.Label>
              <Form.Control
                className={cx(styles.formInput, styles.loginFormInput)}
                x-automation-id="password"
                id="password"
                type="password"
                {...register('password', {
                  required: 'register.password_required',
                })}
                placeholder={I18n.t('register.password')}
                isInvalid={errors.password}
              />
              <span className={styles.loginFormInputIcon}>
                <Lock color="#000000" size={20} />
              </span>
              <Form.Control.Feedback type="invalid" className="m-0">
                {I18n.t(errors?.password?.message || '')}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
        </div>
        <div className="col-md-12 mb-2 d-flex justify-content-end">
          <Link
            to="/forgotpassword"
            x-automation-id="forgot-password-link"
            id="forgot-password-link"
            className={cx(styles.linkText, styles.forgotPasswordText)}
          >
            {I18n.t('login.forgot_password')}
          </Link>
        </div>
        <div className="col-md-12 mt-2">
          <ButtonWithIcon
            type="submit"
            text={I18n.t('common.next')}
            icon={<ArrowRight color="#FFFFFF" />}
            iconToRight={true}
            x-automation-id="next-btn"
            id="next-btn"
            className={styles.customButton}
          />
        </div>
        <div className="col-md-12 mt-4 d-flex justify-content-center">
          <p className={styles.alreadyRegisteredText}>{I18n.t('login.troubles')}</p>
        </div>
        <div className="col-md-12 d-flex justify-content-center">
          <a
            href={'mailto:' + themeConfig?.cntEmail}
            x-automation-id="contact-admin"
            id="contact-admin"
            className={styles.linkText}
          >
            {I18n.t('login.contact_admin')}
          </a>
        </div>
      </div>
    </Form>
  )
}

export default LoginForm
