import { PageParams } from '../../Common/Models'
import {
  AddRequestActions,
  ACTION_ADD_REQUEST_START,
  ACTION_ADD_REQUEST_SUCCESS,
  ACTION_ADD_REQUEST_FAILURE,
  ACTION_ADD_REQUEST_CLEAR,
} from '../Actions/addRequest.actions'
import {
  GetRequestsActions,
  ACTION_GET_REQUESTS_START,
  ACTION_GET_REQUESTS_SUCCESS,
  ACTION_GET_REQUESTS_FAILURE,
} from '../Actions/requestList.actions'
export interface RequestReducerState {
  addRequest: {
    success?: boolean
    statusCode?: number
    errorMessage?: string
  }
  RequestList?: Array<any>
  totalRecords?: number
  pageParams?: PageParams
  activePage: number
  Request?: any
  showDoNotSellRequest: boolean
  getRequestList: {
    success?: boolean
  }
}
export const initialState: RequestReducerState = {
  addRequest: {
    success: false,
  },
  activePage: 1,
  RequestList: [],
  totalRecords: null,
  showDoNotSellRequest: false,
  getRequestList: {
    success: false,
  },
}

export const RequestReducer = (
  state = initialState, //NOSONAR
  action: AddRequestActions | GetRequestsActions
): RequestReducerState => {
  switch (action.type) {
    case ACTION_ADD_REQUEST_START:
      return {
        ...state,
        addRequest: {
          errorMessage: '',
        },
      }
    case ACTION_ADD_REQUEST_SUCCESS:
      return {
        ...state,
        addRequest: {
          ...action.payload.data,
        },
      }
    case ACTION_ADD_REQUEST_FAILURE:
      return {
        ...state,
        addRequest: {
          errorMessage: action.payload.errorMessage,
          success: false,
        },
      }
    case ACTION_ADD_REQUEST_CLEAR:
      return {
        ...state,
        addRequest: {
          errorMessage: '',
          success: false,
        },
      }
    case ACTION_GET_REQUESTS_START:
      return {
        ...state,
        pageParams: action.payload,
        getRequestList: {
          success: false,
        },
      }
    case ACTION_GET_REQUESTS_SUCCESS:
      return {
        ...state,
        RequestList: action.payload.data,
        totalRecords: action.payload.totalRecords,
        activePage: action.payload.activePage,
        showDoNotSellRequest: action.payload.doNotSellRequest,
        getRequestList: {
          success: true,
        },
      }
    case ACTION_GET_REQUESTS_FAILURE:
      return {
        ...state,
        RequestList: [],
        totalRecords: 0,
        activePage: 1,
        getRequestList: {
          success: action?.payload?.isSuccess,
        },
      }
    default:
      return state
  }
}
