import { Dispatch } from 'react'
import { Action } from 'redux'

export const ACTION_SET_USER_DATA = 'ACTION_SET_USER_DATA'
export const ACTION_SET_USER_DATA_SUCCESS = 'ACTION_SET_USER_DATA_SUCCESS'
export const ACTION_SET_USER_DATA_FAILED = 'ACTION_SET_USER_DATA_FAILED'

export interface ActionSetUserData extends Action {
  type: typeof ACTION_SET_USER_DATA
  payload: {
    userData: any
  }
}

export type SetUserDataActions = ActionSetUserData

const dispatchSetUserData = (userData: any): ActionSetUserData => ({
  type: ACTION_SET_USER_DATA,
  payload: {
    userData,
  },
})

export function actionSetUserData(data: any): (dispatch: Dispatch<SetUserDataActions>) => Promise<void> {
  return async (dispatch: Dispatch<SetUserDataActions>) => {
    dispatch(dispatchSetUserData(data))
  }
}
