import { createStore, compose, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { syncTranslationWithStore } from 'react-redux-i18n'
import rootReducer from './reducers'
import translations from '../l10n/translations'
import { dispatchLoadTranslations, dispatchSetLocale } from '../l10n/Actions/i18n'
import { SessionStorage } from 'Utils/SessionStorageWrapper'
import { SessionStorageKeyNames } from 'Modules/Common/Enums'

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)))
syncTranslationWithStore(store)

const lang = SessionStorage.get(SessionStorageKeyNames.Language) || 'en'
store.dispatch(dispatchSetLocale(lang))
store.dispatch(dispatchLoadTranslations(translations))

export default store
