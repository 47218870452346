import { register, verifyCode, sendCode, login, authToast, moreDetails, forgetPass } from './Auth/de'
import { request } from './Requests/de'
import { profileSettings } from './ProfileSettings/de'
import { LocaleMessagesType } from './en'

export const de: LocaleMessagesType = {
  header: {
    logout: 'Abmelden',
    settings: 'Profileinstellungen',
    help: 'Hilfe',
  },
  sidebar: {
    dsar: 'DSAR',
    tenant_health: 'Gesundheit der Mieter',
    application_health: 'Anwendungszustand',
    dsar_workspace: 'DSAR-Arbeitsbereich',
    tenants: 'Mieter',
    users: 'Benutzer',
    scanners: 'Scanner',
    data_sources: 'Datenquellen',
    classifications: 'Klassifizierungen',
    privacy_portal: 'Datenschutzportal',
    system_settings: 'Systemeinstellungen',
    terms_of_service: 'Nutzungsbedingungen',
  },
  common: {
    send: 'Senden',
    on: 'An',
    off: 'Aus',
    save: 'Speichern',
    submit: 'Senden',
    cancel: 'Abbrechen',
    done: 'Fertig',
    add: 'Hinzufügen',
    update: 'Aktualisieren',
    location: 'Standort',
    locationRequired: 'Standort ist erforderlich',
    invalidEmail: 'Ungültiges E-Mail-Adressformat',
    invalidUrl: 'Ungültige URL-Adresse',
    toast: {
      something_went_wrong: 'Etwas ist schief gelaufen',
      no_results_found: 'Keine Ergebnisse gefunden.',
      jwtTokenExpired: 'Ihre Sitzung ist abgelaufen',
    },
    next: 'Weiter',
    saveAndNext: 'Speichern weiter',
    back: 'Zurück',
    invite: 'Einladen',
    not_found: 'Die angeforderte Seite konnte nicht gefunden werden',
    error_boundry_text: 'Beim Laden dieses Abschnitts ist ein Fehler aufgetreten.',
    error_boundry_reload: 'Diese Seite neu laden',
    ok: 'OK',
    select: 'wählen..',
    showingToOfResults: 'Es werden %{from} bis %{to} von %{total} Ergebnissen angezeigt',
  },
  profile_settings: profileSettings,
  auth_toast: authToast,
  login: login,
  send_code: sendCode,
  verify_code: verifyCode,
  register: register,
  moreDetails: moreDetails,
  request: request,
  forgetPass: forgetPass,
}
