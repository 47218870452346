import {
  ACTION_REGISTER,
  ACTION_REGISTER_FAILED,
  ACTION_REGISTER_SUCCESS,
  RegisterActions,
} from '../Actions/register.actions'

import {
  ACTION_VERIFY_CODE,
  ACTION_VERIFY_CODE_FAILED,
  ACTION_VERIFY_CODE_SUCCESS,
  VerifyCodeActions,
} from '../Actions/verify-code.actions'

import {
  ACTION_RESEND_CODE,
  ACTION_RESEND_CODE_FAILED,
  ACTION_RESEND_CODE_SUCCESS,
  ResendCodeActions,
} from '../Actions/resend-code.actions'

import { ACTION_SET_USER_DATA, SetUserDataActions } from '../Actions/user-data.actions'

import { ACTION_LOGOUT, LogoutActions } from '../Actions/logout.actions'

import {
  ACTION_LOGIN,
  ACTION_LOGIN_FAILED,
  ACTION_LOGIN_SUCCESS,
  ACTION_LOGIN_CANCEL,
  ACTION_PARTIAL_LOGIN,
  LoginActions,
} from '../Actions/login.actions'

import {
  ACTION_FORGOT_PASSWORD,
  ACTION_FORGOT_PASSWORD_FAILED,
  ACTION_FORGOT_PASSWORD_SUCCESS,
  ForgotPasswordActions,
} from '../Actions/forget-password.actions'
import {
  ACTION_VERIFY_RESET_PASSWORD_CODE,
  ACTION_VERIFY_RESET_PASSWORD_CODE_FAILED,
  ACTION_VERIFY_RESET_PASSWORD_CODE_SUCCESS,
  VerifyResetPasswordCodeActions,
} from '../Actions/verify-password-link.actions'
import {
  ACTION_RESET_PASSWORD,
  ACTION_RESET_PASSWORD_FAILED,
  ACTION_RESET_PASSWORD_SUCCESS,
  ResetPasswordActions,
} from '../Actions/reset-password.actions'

import {
  ACTION_GET_INVITE_INFO,
  ACTION_GET_INVITE_INFO_FAILED,
  ACTION_GET_INVITE_INFO_SUCCESS,
  GetInviteInfoActions,
} from '../Actions/invite-info.actions'

import { InviteUserInfoModel, UserModel } from '../Models/user.model'

export interface AuthReducerState {
  errorMessage: string
  userData: UserModel | null
  isAuthenticated: boolean
  isVerified: boolean
  inviteInfo: InviteUserInfoModel | null
  resendCodeSuccess: boolean
  verifyEmail: string
  redirectToRegister: boolean
  forgotPassword?: {
    success: boolean
    errorMessage: string
    statusCode?: number
  }
  verifyResetPasswordLink?: {
    success: boolean
    errorMessage: string
    statusCode?: number
  }
  resetPassword?: {
    success: boolean
    errorMessage: string
    statusCode?: number
  },
  signUpDetails?: any
}

export const initialState: AuthReducerState = {
  errorMessage: '',
  userData: null,
  inviteInfo: null,
  isAuthenticated: false,
  isVerified: false,
  resendCodeSuccess: false,
  verifyEmail: '',
  redirectToRegister: false,
}

type AuthActions =
  | RegisterActions
  | VerifyCodeActions
  | ResendCodeActions
  | SetUserDataActions
  | LogoutActions
  | LoginActions
  | ForgotPasswordActions
  | VerifyResetPasswordCodeActions
  | ResetPasswordActions
  | GetInviteInfoActions
export const AuthReducer = (
  state = initialState, //NOSONAR
  action: AuthActions
): AuthReducerState => {
  switch (action.type) {
    case ACTION_REGISTER:
      return {
        ...state,
        errorMessage: '',
        verifyEmail: '',
        signUpDetails: action.payload.data
      }
    case ACTION_REGISTER_SUCCESS:
      return {
        ...state,
        errorMessage: '',
        isAuthenticated: true,
        verifyEmail: action.payload.email,
      }
    case ACTION_REGISTER_FAILED:
      return {
        ...state,
        isAuthenticated: false,
        errorMessage: action.payload.errorMessage,
      }
    case ACTION_VERIFY_CODE:
      return {
        ...state,
        errorMessage: '',
      }
    case ACTION_VERIFY_CODE_FAILED:
      return {
        ...state,
        isVerified: false,
        errorMessage: action.payload.errorMessage,
      }
    case ACTION_VERIFY_CODE_SUCCESS:
      return {
        ...state,
        isVerified: true,
        errorMessage: '',
        userData: action.payload.user || null,
        inviteInfo: null,
        resendCodeSuccess: false,
      }
    case ACTION_RESEND_CODE:
      return {
        ...state,
        errorMessage: '',
        resendCodeSuccess: false,
      }
    case ACTION_RESEND_CODE_FAILED:
      return {
        ...state,
        errorMessage: action.payload.errorMessage,
        inviteInfo: null,
        resendCodeSuccess: false,
      }
    case ACTION_RESEND_CODE_SUCCESS:
      return {
        ...state,
        errorMessage: '',
        resendCodeSuccess: true,
      }
    case ACTION_SET_USER_DATA:
      return {
        ...state,
        errorMessage: '',
        isAuthenticated: true,
        isVerified: true,
        userData: action.payload.userData,
      }
    case ACTION_LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        isVerified: false,
        userData: null,
        inviteInfo: null,
      }
    case ACTION_LOGIN:
      return {
        ...state,
        verifyEmail: '',
      }
    case ACTION_PARTIAL_LOGIN:
      return {
        ...state,
        redirectToRegister: true,
      }
    case ACTION_LOGIN_CANCEL:
      return {
        ...state,
        verifyEmail: '',
        isAuthenticated: false,
        redirectToRegister: action.payload.redirectToRegister,
      }
    case ACTION_LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        verifyEmail: action.payload.email,
      }
    case ACTION_LOGIN_FAILED:
      return {
        ...state,
        isAuthenticated: false,
      }

    case ACTION_FORGOT_PASSWORD:
      return {
        ...state,
        forgotPassword: {
          success: false,
          errorMessage: '',
          statusCode: null,
        },
      }
    case ACTION_FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPassword: {
          success: action.payload.data.success,
          errorMessage: '',
          statusCode: action.payload.data.statusCode,
        },
      }
    case ACTION_FORGOT_PASSWORD_FAILED:
      return {
        ...state,
        forgotPassword: {
          success: action.payload.data.success,
          errorMessage: action.payload.data.message,
          statusCode: action.payload.data.statusCode,
        },
      }

    case ACTION_VERIFY_RESET_PASSWORD_CODE:
      return {
        ...state,
        verifyResetPasswordLink: {
          success: false,
          errorMessage: '',
          statusCode: null,
        },
      }
    case ACTION_VERIFY_RESET_PASSWORD_CODE_SUCCESS:
      return {
        ...state,
        verifyResetPasswordLink: {
          success: action.payload.data.success,
          errorMessage: '',
          statusCode: action.payload.data.statusCode,
        },
      }
    case ACTION_VERIFY_RESET_PASSWORD_CODE_FAILED:
      return {
        ...state,
        verifyResetPasswordLink: {
          success: action.payload.data.success,
          errorMessage: action.payload.data.message,
          statusCode: action.payload.data.statusCode,
        },
      }

    case ACTION_RESET_PASSWORD:
      return {
        ...state,
        resetPassword: {
          success: false,
          errorMessage: '',
          statusCode: null,
        },
      }
    case ACTION_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPassword: {
          success: action.payload.data.success,
          errorMessage: '',
          statusCode: action.payload.data.statusCode,
        },
      }
    case ACTION_RESET_PASSWORD_FAILED:
      return {
        ...state,
        resetPassword: {
          success: action.payload.data.success,
          errorMessage: action.payload.data.message,
          statusCode: action.payload.data.statusCode,
        },
      }

    case ACTION_GET_INVITE_INFO:
      return {
        ...state,
        errorMessage: '',
      }
    case ACTION_GET_INVITE_INFO_FAILED:
      return {
        ...state,
        errorMessage: action.payload.errorMessage,
        inviteInfo: null,
      }
    case ACTION_GET_INVITE_INFO_SUCCESS:
      return {
        ...state,
        errorMessage: '',
        inviteInfo: action.payload.inviteInfo,
      }
    default:
      return state
  }
}
