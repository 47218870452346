import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { LogoutStatus, ResponseCodes } from 'Modules/Common/Enums/Common'
import { getEnvironmentVariables } from '../../Config'
import { BroadcastChannelMessages, SessionStorageKeyNames, StatusCodes } from '../../Modules/Common/Enums'
import { SessionStorage } from '../SessionStorageWrapper/index'
import LogOutAllTabs from '../LogOutAllTabs'

class Http {
  private instance: AxiosInstance | null = null

  private get http(): AxiosInstance {
    return this.instance != null ? this.instance : this.initHttp()
  }

  // We can use the following function to inject the JWT token through an interceptor
  // We get the `accessToken` from the SessionStorage that we set when we authenticate
  private injectToken(config: AxiosRequestConfig): AxiosRequestConfig {
    try {
      const tempToken = SessionStorage.get(SessionStorageKeyNames.TempToken)

      if (tempToken && config && config.url && config.url.includes('/users/mfa/verify')) {
        config.headers.Authorization = `Bearer ${tempToken}`
      }
      return config
    } catch (error: any) {
      throw new Error(error)
    }
  }
  initHttp() {
    const envVars = getEnvironmentVariables()
    const httpAxios = axios.create({
      baseURL: envVars?.API_URL,
      withCredentials: true,
    })

    httpAxios.interceptors.request.use(this.injectToken, error => Promise.reject(error))

    httpAxios.interceptors.response.use(
      response => response,
      (error: any) => {
        return this.handleError(error)
      }
    )

    this.instance = httpAxios
    return httpAxios
  }

  request<T = any, R = AxiosResponse<T>>(config: AxiosRequestConfig): Promise<R> {
    return this.http.request(config)
  }

  get<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.http.get<T, R>(url, config)
  }

  post<T = any, R = AxiosResponse<T>>(url: string, data?: T, config?: AxiosRequestConfig): Promise<R> {
    return this.http.post<T, R>(url, data, config)
  }

  put<T = any, R = AxiosResponse<T>>(url: string, data?: T, config?: AxiosRequestConfig): Promise<R> {
    return this.http.put<T, R>(url, data, config)
  }

  delete<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.http.delete<T, R>(url, config)
  }

  // Handle global app errors
  // We can handle generic app errors depending on the status code
  private handleError(error: any) {
    const { status } = error.response

    switch (status) {
      case StatusCodes.InternalServerError: {
        // Handle InternalServerError
        break
      }
      case StatusCodes.Forbidden: {
        // Handle Forbidden
        break
      }
      case StatusCodes.Unauthorized: {
        // Handle Unauthorized
        if (error?.response?.data?.code !== ResponseCodes.Unauthorized) {
          error.response.data = {
            code: ResponseCodes.Unauthorized,
            message: 'Unauthorized',
          }
        }
        SessionStorage.removeKey(SessionStorageKeyNames.UserData)
        SessionStorage.removeKey(SessionStorageKeyNames.TempToken)
        SessionStorage.removeKey(SessionStorageKeyNames.TempTokenExpiry)
        LogOutAllTabs.postMessage(BroadcastChannelMessages.Logout)
        let tenantUrl = window.location.pathname.split('/')[1]
        window.location.href = window.location.origin + `/${tenantUrl}/login?status=${LogoutStatus.status}`
        break
      }
      case StatusCodes.TooManyRequests: {
        // Handle TooManyRequests
        break
      }
    }

    return Promise.reject(error)
  }
}

export const http = new Http()
