export const urlEmailRegx = {
  email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
  url: /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&=]*)/,
}

export const phoneNumber = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/

export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const departmentList = [{ value: '1', label: 'IT' }]
export const countryValue = { value: 'USA', label: 'USA' }

export const DefaultSort = {
  columnName: 'created_at',
  sortOrder: ['desc', 'asc'],
}

export const RquestStatusDetails = {
  completed: 'closed',
  inProgress: 'in_progress',
  failed: 'failed',
  open: 'open',
}

export const cytrioPrivacyPolicyURL = 'https://cytrio.com/legal/privacypolicy/'
export const cytrioTermsofServiceURL = 'https://www.cytrio.com/legal/TermsofService/'

export const showIntervalLoader = false
