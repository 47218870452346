export const authToast = {
  sign_up_pending: "Veuillez terminer le processus d'inscription de votre compte",
  code_resent_success: 'Code renvoyé avec succès',
  invalid_code: 'Code saisi invalide',
  code_expired:
    "Votre code de vérification à usage unique est invalide ou a expiré. Veuillez cliquer sur 'Renvoyer' pour demander un nouveau code",
  invalid_invite: "Code d'invitation invalide",
  user_not_exists: "L'utilisateur n'existe pas",
  captch_expited: 'Google captcha a expiré',
  user_already_exists: 'Un compte avec cet e-mail existe déjà. Veuillez vous connecter',
  invalid_login:
    "Authentification invalide. Cliquez sur S'inscrire pour enregistrer un nouveau compte ou cliquez sur Mot de passe oublié pour réinitialiser votre mot de passe",
  toomanycall: 'Vous avez envoyé trop de demandes dans un laps de temps donné. Réessayez plus tard!'
}

export const register = {
  information: 'Informations',
  all_rights_reserved: 'CYTRIO, Inc. Tous droits réservés',
  verification_code: 'Code de vérification',
  moreDetails: 'En savoir plus sur vous-même',
  about: 'À propos',
  help: 'Aide',
  privacy_policy: 'Politique de confidentialité',
  terms_and_conditions: "Conditions d'utilisation",
  data_captured:
    "Les données collectées lors de l'inscription seront utilisées pour fournir un accès au centre de confidentialité, améliorer l'expérience utilisateur et fournir des services souscrits auprès de CYTRIO",
  pwd_must_meet: 'Le mot de passe doit correspondre',
  following_requirements: 'les exigences suivantes:',
  one_letter: 'Au moins une lettre',
  one_capital: 'Au moins une lettre majuscule',
  one_number: 'Au moins un numéro',
  eight: 'Comporter au moins 8 caractères',
  create_account: 'Créer un compte',
  company_name: "Nom de l'entreprise",
  company_name_required: "Veuillez saisir le nom de l'entreprise",
  first_name: 'Prénom',
  first_name_required: 'Veuillez entrer le prénom',
  last_name: 'Nom de famille',
  last_name_required: 'Veuillez entrer le nom de famille',
  email: 'E-mail',
  email_required: 'Veuillez saisir une adresse e-mail valide',
  phone: 'Téléphone',
  phone_required: 'Veuillez entrer le téléphone',
  phoneNumberInvalid: 'Numéro de téléphone non valide',
  password: 'Mot de passe',
  country: 'Pays',
  country_required: 'Veuillez saisir le pays',
  state: 'État',
  state_required: "Veuillez entrer l'état",
  password_required: 'Veuillez entrer un mot de passe valide',
  confirm_password: 'Confirmer le mot de passe',
  confirm_password_required: 'Veuillez saisir le mot de passe de confirmation',
  confirm_password_not_match: 'Le mot de passe ne correspond pas',
  agree: "J'accepte les CYTRIO",
  terms: "Conditions d'utilisation",
  terms_required: 'Veuillez accepter les termes et conditions',
  already_registered: 'Déjà inscrit ?',
  sign_in: 'Connexion',
  trouble_sign_up: "Si vous rencontrez des difficultés pour vous inscrire, s'il vous plaît",
  contact_admin: "Contacter l'administrateur",
  captcha_required: 'Veuillez vérifier le captcha Google',
}

export const verifyCode = {
  title: 'Centre de confidentialité',
  resend: 'Renvoyer',
  sent_to_email: 'Un code de vérification à 6 chiffres a été envoyé à votre identifiant de messagerie',
  enter_code: 'Veuillez entrer le code ci-dessous',
  submit_btn: 'Vérifier',
  valid_for: 'Votre code sera valide pour',
  didnt_receive: "Vous n'avez pas reçu de code ?",
  code_required: 'Veuillez entrer le code',
  valid_code: 'Veuillez saisir uniquement des chiffres',
  mins: 'Min',
}

export const sendCode = {
  title: 'Envoyer le code de vérification',
  send_code_to: 'Envoyer le code de vérification à 6 chiffres à ',
  send_code: 'Envoyer le code',
  email: 'E-mail',
  phone: 'Téléphone',
  email_text: 'Société...@nom.com ',
  phone_text: '1234-567-890 ',
}

export const login = {
  login: 'Connexion',
  sign_in_button: 'Connexion',
  sign_in: 'Centre de confidentialité',
  sign_in_cred: 'Connectez-vous avec vos identifiants',
  sign_up: "S'inscrire",
  email: 'E-mail',
  password: 'Mot de passe',
  dont_have_account: "Vous n'avez pas de compte ?",
  create_account: 'Créer un compte',
  troubles: "Si vous rencontrez des problèmes pour vous connecter, s'il vous plaît",
  contact_admin: "Contacter l'administrateur",
  forgot_password: 'Mot de passe oublié ?',
  remember_password: 'Mémoriser le mot de passe ?',
  data_captured:
    "Les données capturées lors de la connexion seront utilisées pour fournir un accès au centre de confidentialité, améliorer l'expérience utilisateur et fournir des services souscrits auprès de CYTRIO",
}

export const moreDetails = {
  tellUsMore: 'Dites-nous en plus sur vous-même',
  dateOfBirth: 'Date de naissance',
  dateOfBirth_required: 'Veuillez saisir la date de naissance',
  zipcode: 'Code postal',
  address: 'Adresse',
  city: 'Ville',
}

export const forgetPass = {
  forgetPass: 'Mot de passe oublié ?',
  forgetPassDescription:
    'Entrez votre adresse e-mail et nous vous enverrons un lien pour réinitialiser votre mot de passe',
  emaildidNotFound: "Cette adresse est invalide ou n'est associée à aucun compte",
  backToLogin: 'Retour à la connexion',
  passwordResetEmail: 'E-mail de réinitialisation du mot de passe envoyé',
  passwordResetEmailContent: 'Un e-mail a été envoyé à votre adresse e-mail enregistrée.',
  passwordResetSubContent: "Cliquez sur le lien fourni dans l'e-mail pour réinitialiser le mot de passe.",
  linkExpired: 'Ce lien a expiré',
  linkExpiredContent: 'Cliquez ici pour envoyer un nouveau lien',
  resendLink: 'Renvoyer le lien',
  resetPass: 'Réinitialiser le mot de passe',
  resetPassContent: 'Entrez votre nouveau mot de passe',
  passwordChanged: 'Mot de passe modifié',
  passwordChangedSuccess: 'Votre mot de passe a été modifié avec succès.',
  newPassword: 'Nouveau mot de passe',
  newPasswordSameOld: "Le nouveau mot de passe ne peut pas être le même que l'ancien mot de passe",
  addressnotfound:'Si l utilisateur existe, un e-mail sera envoyé.'
}
