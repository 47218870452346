import {
  GetCitiesActions,
  ACTION_GET_CITIES,
  ACTION_GET_CITIES_SUCCESS,
  ACTION_GET_CITIES_FAILURE,
} from './cities.actions';

export interface CitiesReducerState {
  citiesDropdown?: {
    success?: boolean;
    message?: string;
    statusCode?: number;
    getCitiesDropDownLoader?: boolean;
    errorMessage?: string;
    data?: any;
  };
}

export const initialState: CitiesReducerState = {
  citiesDropdown: {
    success: false,
  },
};

export const CitiesReducer = (
  state = initialState, //NOSONAR
  action: GetCitiesActions
): CitiesReducerState => {
  switch (action.type) {
    case ACTION_GET_CITIES:
      return {
        ...state,
        citiesDropdown: {
          success: true,
          errorMessage: '',
        },
      };
    case ACTION_GET_CITIES_SUCCESS:
      return {
        ...state,
        citiesDropdown: {
          data: action.payload.data,
          success: true,
        },
      };
    case ACTION_GET_CITIES_FAILURE:
      return {
        ...state,
        citiesDropdown: {
          errorMessage: action.payload.errorMessage,
          getCitiesDropDownLoader: false,
          success: false,
        },
      };
    default:
      return state;
  }
};
