import React, { useEffect } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { AppStateType } from '../../Store/reducers'
import { actionSetUserData } from '../Auth/Actions/user-data.actions'
import styled from 'styled-components'
import Header from './Header'
import Footer from './Footer'
import { Scrollbars } from 'react-custom-scrollbars'
import { SessionStorage } from '../../Utils/SessionStorageWrapper'
import { BroadcastChannelMessages, SessionStorageKeyNames } from '../../Modules/Common/Enums'
import Loader from 'Modules/Common/Components/Loader'
import loadable from '@loadable/component'
import styles from './Layout.module.css'
import { dispatchLogout } from 'Modules/Auth/Actions/logout.actions'
import LogOutAllTabs from 'Utils/LogOutAllTabs'
import { UserModel } from 'Modules/Auth/Models/user.model'

declare global {
  interface Window {
    pendo: any
  }
}

// Theme config default font and color
const Main = styled.main`
  font-family: ${props => props.theme.font};
  color: ${props => props.theme.fontColor};
  flex: 1;
  display: flex;
  position: relative;
`

const Requests = loadable(() => import('../Requests'), {
  fallback: <Loader />,
})

const ProfileSettings = loadable(() => import('../ProfileSettings'), {
  fallback: <Loader />,
})

const Layout = () => {
  const { userData, isAuthenticated } = useSelector((state: AppStateType) => state.AuthReducer)
  const { tenantUrl } = useSelector((state: AppStateType) => state.ConfigReducer)

  const dispatch = useDispatch()

  const userDataSessionStorage = SessionStorage.get(SessionStorageKeyNames.UserData)
  const userDataParsed: UserModel = userDataSessionStorage && JSON.parse(userDataSessionStorage)

  useEffect(() => {
    if (!userDataParsed) {
      return
    }
    if (userData) {
      return
    }
    if (userDataParsed.tenantUrl !== tenantUrl) {
      LogOutAllTabs.postMessage(BroadcastChannelMessages.Logout)
      SessionStorage.removeKey(SessionStorageKeyNames.UserData)
      SessionStorage.removeKey(SessionStorageKeyNames.TempToken)
      SessionStorage.removeKey(SessionStorageKeyNames.TempTokenExpiry)
      dispatch(dispatchLogout())
      return
    }
    dispatch(actionSetUserData(userDataParsed))
  }, [userDataParsed, dispatch, tenantUrl])

  if (userDataParsed) {
    // in your authentication promise handler or callback
    window.pendo?.initialize({
      visitor: {
        id: userDataParsed?.pcUserId, // Required if user is logged in
        email: userDataParsed?.email, // Recommended if using Pendo Feedback, or NPS Email
        full_name: userDataParsed?.firstName + ' ' + userDataParsed?.lastName, // Recommended if using Pendo Feedback
        // role:         // Optional
        // You can add any additional visitor level key-values here,
        // as long as it's not one of the above reserved names.
      },

      account: {
        id: userDataParsed?.tenantId, // Required if using Pendo Feedback
        name: userDataParsed?.tenantName, // Optional
        // is_paying:    // Recommended if using Pendo Feedback
        // monthly_value:// Recommended if using Pendo Feedback
        // planLevel:    // Optional
        // planPrice:    // Optional
        // creationDate: // Optional

        // You can add any additional account level key-values here,
        // as long as it's not one of the above reserved names.
      },
    })
  }

  if (!isAuthenticated) {
    return <Redirect to={`/login`} />
  }

  return (
    <>
      <Main>
        <div className={styles.appMainOuter}>
          <Header />
          <Scrollbars
            autoHeightMax=""
            autoHide
            renderTrackVertical={() => <div className="track-vertical" />}
            renderThumbVertical={() => <div className="thumb-vertical" />}
          >
            <Switch>
              <Route exact path="/" render={() => <Redirect to="/requests" />} />
              <Route path="/requests" component={Requests} />
              <Route exact path="/profile-settings" component={ProfileSettings} />
            </Switch>
          </Scrollbars>
          <Footer />
        </div>
      </Main>
    </>
  )
}

export default Layout
