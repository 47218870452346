let prefixTenantUrl = true;

export const auth = {
  getInviteInfo: (tenantUrl: string) => `${prefixTenantUrl ? '/' + tenantUrl : ''}/api/v1/tenants/users/register/details`,
  login: (tenantUrl: string) => `${prefixTenantUrl ? '/' + tenantUrl : ''}/api/v1/tenants/${tenantUrl}/users/signin`,
  verifyCode: (tenantUrl: string) => `${prefixTenantUrl ? '/' + tenantUrl : ''}/api/v1/tenants/${tenantUrl}/users/mfa/verify`,
  resendCode: (tenantUrl: string) => `${prefixTenantUrl ? '/' + tenantUrl : ''}/api/v1/tenants/${tenantUrl}/users/mfa/resend`,
  register: (tenantUrl: string) => `${prefixTenantUrl ? '/' + tenantUrl : ''}/api/v1/tenants/${tenantUrl}/users/signup`,
  userProfile: (tenantUrl: string) => `${prefixTenantUrl ? '/' + tenantUrl : ''}/api/v1/tenants/${tenantUrl}/users/profile`,
  logout: (tenantUrl: string) => `${prefixTenantUrl ? '/' + tenantUrl : ''}/api/v1/tenants/${tenantUrl}/users/logout`,
  forgotPassword: (tenantUrl: string) => `${prefixTenantUrl ? '/' + tenantUrl : ''}/api/v1/tenants/${tenantUrl}/users/password/forgot`,
  verifyForgotPasswordLink: (tenantUrl: string) => `${prefixTenantUrl ? '/' + tenantUrl : ''}/api/v1/tenants/${tenantUrl}/users/password/reset/verify`,
  resetPassword: (tenantUrl: string) => `${prefixTenantUrl ? '/' + tenantUrl : ''}/api/v1/tenants/${tenantUrl}/users/password/reset`,
}

export const profileSettings = {
  changePassword: (tenantUrl: string) => `${prefixTenantUrl ? '/' + tenantUrl : ''}/api/v1/tenants/${tenantUrl}/users/password`,
}

export const tenant = {
  checkTenant: (tenantUrl: string) => `${prefixTenantUrl ? '/' + tenantUrl : ''}/api/v1/tenants/${tenantUrl}`,
}

export const dsar = {
  request: (tenantUrl: string) => `${prefixTenantUrl ? '/' + tenantUrl : ''}/api/v1/tenants/${tenantUrl}/dsar`,
 downloadReport: (requestId: number, filename:string, tenantUrl: string) => `${prefixTenantUrl ? '/' + tenantUrl : ''}/api/v1/tenants/${tenantUrl}/dsar/${requestId}/${filename}/reports`,
}
export const common = {
  location: (tenantUrl: string) => `${prefixTenantUrl ? '/' + tenantUrl : ''}/api/v1/locations`,
  cities: (tenantUrl: string) => `${prefixTenantUrl ? '/' + tenantUrl : ''}/api/v1/cities`,
}
